import * as React from 'react';
import { Table } from 'react-bootstrap';
import TablePagination from '../../components/TablePagination';
import TableRow from '../../components/TableRow';
import { formatDecimal } from '../../core/utils/functions';
import { CustomerSegmentationModel } from '../../core/model/customer_segmentation';
import { FilterCustomerSegmentation } from '../../core/repositories/customer_segmentation.repository';
import moment from 'moment';

interface Props {
	search: FilterCustomerSegmentation;
	data: CustomerSegmentationModel[];
	onChangeSort: (search: FilterCustomerSegmentation, loading: boolean) => void;
}

export const TableCustomerSegmentation: React.FC<Props> = (props) => {
	function changeSort(value: string, prop: string) {
		const aux: any = {};
		aux[prop] = value;
		props.search.sort = aux;
		props.onChangeSort(props.search, true);
	}
	function formatFrequency(value: string) {

		let freq = Number.parseFloat(value);
		if (freq == 0) return '-';

		let month = Number.parseInt(value); // integerPart
		let day = Math.round((freq - month) * 30); // decimalPart

		let month_part = `${month} ${month > 1 ? 'meses' : 'mês'}`;
		let and_part = month > 0 && day > 0 ? ' e ' : '';
		let day_part = `${day} ${day > 1 ? 'dias' : 'dia'}`;

		return `${month > 0 ? month_part : ''} ${and_part} ${day > 0 ? day_part : ''}`;
	}

	return (
		<>
			{props.data.length > 0 && (
				<div className="text-end font-size-15">
					Última atualização do modelo: <b>{moment(props.data[0].date).format('DD/MM/YYYY')}</b>
				</div>
			)}
			<Table striped bordered hover size="sm">
				<thead>
					<tr>
						<TableRow sort={props.search.sort.cluster} onSort={(ev: any) => changeSort(ev, 'cluster')}>
							Cluster
						</TableRow>
						{!props.search.hide_customer && (
							<>
								<TableRow>Cliente</TableRow>
								<TableRow>Ramo de Atividade</TableRow>
								<TableRow>Cidade</TableRow>
							</>
						)}
						<TableRow>Categoria</TableRow>
						<TableRow sort={props.search.sort.avg_ticket} onSort={(ev: any) => changeSort(ev, 'avg_ticket')}>
							Ticket Médio
						</TableRow>
						<TableRow sort={props.search.sort.avg_quantity} onSort={(ev: any) => changeSort(ev, 'avg_quantity')}>
							Quantidade Média
							<br />
							Vendida
						</TableRow>
						<TableRow sort={props.search.sort.frequency} onSort={(ev: any) => changeSort(ev, 'frequency')}>
							Frequência de
							<br />
							Venda
						</TableRow>
					</tr>
				</thead>
				<tbody>
					{props.data.length === 0 && (
						<tr className="table-row-header text-center">
							<td colSpan={14}> Nenhum registro encontrado! </td>
						</tr>
					)}
					{props.data.map((_, index) => (
						<tr className="text-center" key={index}>
							<td>{_.cluster}</td>
							{!props.search.hide_customer && (
								<>
									<td>
										[<b>{_.customer_code}</b>] {_.customer_description}
									</td>
									<td>{_.activity_field_description}</td>
									<td>{_.city}</td>
								</>
							)}
							<td>{_.granularity_description}</td>
							<td>{formatDecimal(_.avg_ticket, true)}</td>
							<td>{formatDecimal(_.avg_quantity)}</td>
							<td> {formatFrequency(_.frequency)} </td>
						</tr>
					))}
				</tbody>
			</Table>
			<div>
				<TablePagination
					onChangePage={(value) => {
						props.search.page = value;
						props.onChangeSort(props.search, true);
					}}
					currentPage={props.search.page}
					pageSize={props.search.pageSize}
					count={props.search.total}
				></TablePagination>
			</div>
		</>
	);
};
