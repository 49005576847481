import { CollecionName } from '../config/collection-name';
import { BasicFilter } from '../model/base-model';
import { ChartCohortModel } from '../model/chart_cohort';
import { Repository } from './repository';

export class FilterChartCohort extends BasicFilter<ChartCohortModel> {

	cluster = '';
	absolute: boolean = false;
	type: string = 'retention_client';


}

export class ChartCohortRepository extends Repository<ChartCohortModel> {
	constructor() {
		super(CollecionName.CHART_COHORT);
	}

	public getFilterAny(data: any): Promise<ChartCohortModel> {
		return super.getFilter(data).then((res: any) => {
			return res;
		});
	}


}
