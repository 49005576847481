import * as React from "react";
import { useEffect, useState } from "react";
import { Button, Col, Form, FormControl, InputGroup, Modal, Row, Spinner, Table } from "react-bootstrap";
import { BsSearch } from "react-icons/bs";
import { SearchInput } from "../../components/SearchInput";
import TablePagination from "../../components/TablePagination";
import TableRow from "../../components/TableRow";
import { MatchingValidationsModel } from "../../core/model/matching_validations";
import { ProductModel } from "../../core/model/product";
import { similarity } from "../../core/utils/compare-strings";
import useTable from "../../core/utils/useTable";

interface Props {
  data?: MatchingValidationsModel;
  show: boolean;
  productList: ProductModel[];
  onSave: (data: MatchingValidationsModel) => void;
  onHide: () => void;
}

export const ModalMatchingValidations: React.FC<Props> = (props) => {
  const [productList, setProductList] = useState<ProductModel[]>([]);
  const [productListShow, setProductListShow] = useState<ProductModel[]>([]);
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [searchDescribe, setSearchDescribe] = useState('');
  const [searchCod, setSearchCod] = useState('');
  const { slice, range } = useTable(productListShow, page, pageSize);


  useEffect(() => {
    if (props.show) {
      setPage(1);

      document.getElementById("search")?.focus();
      if (props.data) {
        // var options = props.productList.map(x => {
        //   return {
        //     id: x.id,
        //     description: x.description,
        //     description_search: x.description.toLocaleLowerCase() + x.id,
        //     order: similarity(props.data?.competitor_product_description, x.description)
        //   }
        // }).sort((a, b) => {
        //   return a.order > b.order ? -1 : 1;
        // })
        var options = props.productList
        setProductList([...options]);
        setProductListShow([...options as any]);
      }
    }
  }, [props.show]);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearchName(), 100);
    return () => clearTimeout(timeOutId);
  }, [searchDescribe]);

  useEffect(() => {
    const timeOutId = setTimeout(() => handleSearchId(), 100);
    return () => clearTimeout(timeOutId);
  }, [searchCod]);


  function handleSearchId() {
    var a = productList.filter((x) => searchCod == "" || (x.id.toString() == searchCod));
    setProductListShow([...a]);
    setPage(1);
  }

  function handleSearchName() {
    var a = productList.filter((x) => searchDescribe == "" || (x.description.toLocaleUpperCase().indexOf(searchDescribe.toLocaleUpperCase()) != -1));
    setProductListShow([...a]);
    setPage(1);
  }

  function saveDate(is_similar: boolean, select: ProductModel) {
    if (props.data) {
      props.data.is_similar = is_similar;
      props.data.product_id = select.id;
      props.data.product_description = select.description;
      props.data.match_status = 2;
      props.onSave(props.data);
    }
  }

  return (
    <>
      <Modal
        centered
        aria-labelledby="contained-modal-title-vcenter"
        size="lg"
        className="modal-product"

        {...props}
      >
        <Modal.Header closeButton>
          <Modal.Title
            id="contained-modal-title-vcenter"
            className="text-center font-size-16"
          >
            Produto concorrente: <b>{props.data?.competitor_product_description}</b>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body >

          <Row>
            <Col md={4}>

              <Form.Group >
                <Form.Label className='font-size-14'>Cód. Produto</Form.Label>
                <Form.Control
                  id="searchCod"
                  type="text"
                  placeholder="Todos"
                  onChange={(event) => {
                    setSearchCod(event.target.value);
                    (document.getElementById("search") as any).value = "";
                  }}
                />
              </Form.Group>


            </Col>

            <Col md={8}>

              <Form.Group >
                <Form.Label className='font-size-14'>Descrição</Form.Label>
                <Form.Control
                  id="search"
                  type="text"
                  placeholder="Todos"
                  onChange={(event) => {
                    setSearchDescribe(event.target.value);
                    (document.getElementById("searchCod") as any).value = "";
                  }}
                />
              </Form.Group>


            </Col>

          </Row>
          {/* <InputGroup>
            <FormControl
              type="text"
              placeholder="Pesquisar..."
              onChange={event => setSearch(event.target.value)}
              id="search"
            />
            <div className="absolute right-5 top-5 zindex-9999">
              <BsSearch />
            </div>
          </InputGroup> */}
          <br />
          <div className="min-height-460">

            <Table bordered hover responsive borderless size="sm" className="mb-0 ">
              <thead>
                <tr>
                  <TableRow >
                    Produto Laredo
                  </TableRow>

                  <TableRow>
                    Ação
                  </TableRow>
                </tr>
              </thead>
              <tbody   >
                {slice.length === 0 && (
                  <tr className="table-row-header text-center">
                    <td colSpan={2}> Nenhum registro encontrado! </td>
                  </tr>
                )}
                {slice.map((_: ProductModel, index: number) => (
                  <tr className="text-center" key={index}>
                    <td>
                      [<b>{_.id}</b>] &nbsp;
                      {_.description}
                    </td>
                    <td>
                      <Button variant="secondary" size='sm'
                        onClick={() => {
                          saveDate(true, _);
                        }}>
                        Similar
                      </Button>
                      &nbsp;
                      <Button variant="primary" size='sm'
                        onClick={() => {
                          saveDate(false, _);
                        }}>
                        Específico
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </Table>
          </div>

          <div>
            <TablePagination
              onChangePage={(value) => {
                setPage(value);
              }}
              currentPage={page}
              pageSize={pageSize}
              count={productListShow.length}
            ></TablePagination>
          </div>

        </Modal.Body>

        <Modal.Footer>
          <Button
            variant="danger"
            onClick={() => {
              props.onHide();
            }}
          >
            Cancelar
          </Button>

        </Modal.Footer>
      </Modal>
    </>
  );
};
