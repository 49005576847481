import React, { useEffect, useRef, useState } from "react";
import Image from "react-bootstrap/Image";
import spinner from "../../assets/icons/spinner-black-sm.svg";
import { FaArrowDown, FaArrowUp, FaInfo, FaMinusCircle, FaPlusCircle, FaSave } from "react-icons/fa";
import { Button, OverlayTrigger, Tooltip } from "react-bootstrap";
import { BasePriceCatalogModel, BasePriceCatalogModelGranularidade } from "../../core/model/base_price_catalog";
import { FilterBasePriceCatalog } from "../../core/repositories/base_price_catalog.repository";
import { useToast } from "../../core/contexts/toast";
import { useAuth } from "../../core/contexts/auth";
import { CalcMargin, CalcTrend, formatDecimal, formatPercent } from "../../core/utils/functions";
import SwitchItem from "../../components/Switch";
import NumberFormat from "react-number-format";
import { basePriceCatalogContext } from "../../core/contexts/basePriceCatalogContext";
import { MessageType } from "../../core/config/message-type";
import moment from "moment";
import { useSearchParams } from "react-router-dom";
import { Timer } from "../../core/config/timer";

interface Props {
  data: BasePriceCatalogModel;
  index: number;
  search: FilterBasePriceCatalog;
  onModalBuyers(): void;
  onModalEdit(): void;
}

export const TableTr: React.FC<Props> = (props) => {
  const { toastShow } = useToast();
  const { user } = useAuth();

  const [carregar, setCarregar] = useState(false);
  const timerRef = useRef<NodeJS.Timeout | null>(null);
  const timerRef2 = useRef<NodeJS.Timeout | null>(null);

  const { salvando, carregandoFilho, adicionarLista, removerLista, atualizarInterface, salvarLinhaEditada, salvarLinhaMinima } = basePriceCatalogContext();
  const [isSalved, setIsSalved] = useState(false);
  const [isMinSalved, setIsMinSalved] = useState(false);
  const [search, setSearch] = useState(new FilterBasePriceCatalog());
  const [searchParams, setSearchParams] = useSearchParams();
  useEffect(() => {
    searchParams.forEach((value, key) => {
      if (value != "undefined") {
        (search as any)[key] = value;
      }
    });

    if (search.num_level) {
      carregarDados();
    }
  }, []);

  async function carregarDados() {
    try {
      setCarregar(true);
      if (props.data.flg_aberto) {
        removerLista(props.index);
      } else {
        await adicionarLista(props.search, props.index);
      }

      atualizarInterface();
    } catch (error) {
      toastShow({
        title: "Erro",
        subTitle: "Aconteceu algo inesperado, por favor tente novamente mais tarde!",
        type: "error",
      });
    } finally {
      setCarregar(false);
    }
  }

  function desabilitarEdicao() {
    return props.search.year_week != moment().format("YYYYWW");
  }

  async function automateChange(checked: boolean, item: BasePriceCatalogModel, index: number) {
    try {
      item.is_automated = checked;
      setIsSalved(true);
      if (checked) {
        props.data.is_accepted = false;
        props.data.edit_price_save = props.data.new_price_optimizer;
        props.data.price_edit = props.data.new_price_optimizer;
      }
      const element = document.getElementById("checkbox" + index);
      if (element) {
        (element as any).checked = false;
      }
      await salvarLinhaEditada(props.data, index);
    } catch (error) {
      toastShow({
        title: "Erro!",
        subTitle: MessageType.ERROR_TRY,
        type: "error",
      });
    } finally {
      setIsSalved(false);
    }
  }

  function savePriceEdit() {
    if (timerRef.current) {
      clearTimeout(timerRef.current);
    }
    timerRef.current = setTimeout(async () => {
      try {
        // setIsSalved(true);
        props.data.price_edit = props.data.edit_price_save;
        await salvarLinhaEditada(props.data, props.index);
      } catch (error) {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      } finally {
        setIsSalved(false);
      }
    }, Timer.MILLISECONDS);
  }

  function savePriceMinimum() {
    if (timerRef2.current) {
      clearTimeout(timerRef2.current);
    }
    timerRef2.current = setTimeout(async () => {
      try {
        setIsMinSalved(true);
        props.data.price_minimum = props.data.minimum_price_save;
        await salvarLinhaMinima(props.data, props.index);
      } catch (error) {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      } finally {
        setIsMinSalved(false);
      }
    }, Timer.MILLISECONDS);
  }

  async function isAcceptedChange(checked: boolean, index: number) {
    try {
      setIsSalved(true);
      // props.data.is_salved =true
      if (checked) {
        props.data.is_accepted = true;
        props.data.is_automated = false;
        props.data.edit_price_save = props.data.new_price_optimizer;
        props.data.price_edit = props.data.new_price_optimizer;
      } else {
        props.data.is_accepted = false;
      }
      await salvarLinhaEditada(props.data, index);
    } catch (error) {
      toastShow({
        title: "Erro!",
        subTitle: MessageType.ERROR_TRY,
        type: "error",
      });
    } finally {
      setIsSalved(false);
    }
  }

  function copyText() {
    var label = "";
    var query = "";
    for (let i = 0; i <= props.data.num_level; i++) {
      if ((props.data as any)[BasePriceCatalogModelGranularidade.DESCRICAO[i]])
        label += `${BasePriceCatalogModelGranularidade.INTERFACE[i]?.toString()}:(${(props.data as any)[
          BasePriceCatalogModelGranularidade.CODIGO[i]
        ]?.toString()}) - ${(props.data as any)[BasePriceCatalogModelGranularidade.DESCRICAO[i]]?.toString()}\n`;
    }
    label += `\n AND ${"year_week = " + props.search.year_week + ""} \n`;
    query += `?year_week=${props.search.year_week}`;
    for (let i = 0; i <= props.data.num_level; i++) {
      label += `AND ${BasePriceCatalogModelGranularidade.CODIGO[i]?.toString()} = '${(props.data as any)[
        BasePriceCatalogModelGranularidade.CODIGO[i]
      ]?.toString()}'\n`;
      query += `&${BasePriceCatalogModelGranularidade.CODIGO[i]?.toString()}=${(props.data as any)[BasePriceCatalogModelGranularidade.CODIGO[i]]?.toString()}`;
    }
    query += `&num_nivel=${props.data.num_level}`;
    label += "\n" + window.location.origin + window.location.pathname + query;
    const inputTemporario = document.createElement("textarea");
    inputTemporario.value = label;
    document.body.appendChild(inputTemporario);
    inputTemporario.select();
    document.execCommand("copy");
    document.body.removeChild(inputTemporario);
  }

  function tendenciaPreco(preco1?: number, preco2?: number) {
    if (!preco1 || !preco2) {
      return "-";
    }
    var a = CalcTrend(preco1, preco2);

    return percentTr(a);
  }

  function margemPreco(preco1?: number, preco2?: number) {
    if (!preco1 || !preco2) {
      return "-";
    }
    var a = CalcMargin(preco1, preco2);

    return percentTr(a);
  }

  function percentTr(preco: number) {
    const precisionThreshold = 0.0001; // Definir um limiar de precisão

    if (preco > precisionThreshold) {
      return (
        <>
          <span style={{ color: "green" }}>{formatPercent(preco)}</span>
          <FaArrowUp className="arrow-icon" color="green" />
        </>
      );
    } else if (preco < -precisionThreshold) {
      return (
        <>
          <span style={{ color: "red" }}>{formatPercent(preco)}</span>
          <FaArrowDown className="arrow-icon" color="red" />
        </>
      );
    } else {
      return <span>{formatPercent(preco)}</span>;
    }
  }

  return (
    <>
      {/* Granularidade */}
      <td
        width={200}
        className={
          (props.data.edit ? "is-edit " : "") +
          "  text-start granularidade-nivel" +
          (props.data.is_all_replicated ? " nivel-yellow-" + (props.data.num_level + 1) : " nivel-" + (props.data.num_level + 1))
        }
      >
        <div className="drilldown ">
          <div
            className="text-end"
            style={{
              marginLeft: `${
                0 +
                (props.data.num_level == BasePriceCatalogModelGranularidade.DESCRICAO.length - 1 && props.data.quantity <= 1
                  ? props.data.num_level - 1
                  : props.data.num_level) *
                  12
              }px`,
            }}
          >
            {props.data.num_level > 2 && <div className="type-of-product">{props.data.is_mandatory ? "Obrigatório" : "Substituto"}</div>}

            {(props.data.num_level < BasePriceCatalogModelGranularidade.DESCRICAO.length - 2 ||
              (props.data.num_level == BasePriceCatalogModelGranularidade.DESCRICAO.length - 2 && props.data.quantity > 0)) && (
              <Button
                className="no-border transparent d-flex align-items-center justify-content-center width-28 height-28"
                variant="light"
                size="sm"
                onClick={carregarDados}
                disabled={carregandoFilho}
              >
                {carregar ? <Image src={spinner} className="baixar-icon" /> : props.data.flg_aberto ? <FaMinusCircle /> : <FaPlusCircle />}
              </Button>
            )}
          </div>

          <span className="ms-1 w-100" onDoubleClick={copyText}>
            {(props.data.num_level == 5 || props.data.num_level == 0) &&
              (props.data as any)[BasePriceCatalogModelGranularidade.CODIGO[props.data.num_level]]?.toString() + " - "}
            {(props.data as any)[BasePriceCatalogModelGranularidade.DESCRICAO[props.data.num_level]]
              ? (props.data as any)[BasePriceCatalogModelGranularidade.DESCRICAO[props.data.num_level]]?.toString()
              : "Vazio - " + (props.data as any)[BasePriceCatalogModelGranularidade.CODIGO[props.data.num_level]]?.toString()}
            {/* -{ props.data.cod_familia_subgrupo_produto_capitao} */}

            {props.data.num_level > 0 && window.location.hostname == "localhost" && <> &nbsp; ({props.data.quantity})</>}
          </span>

          {/* <span>{props.data.num_level}</span> */}
        </div>
      </td>

      {/* Estoque Disponível*/}
      <td>
        <OverlayTrigger
          placement="top"
          overlay={
            <Tooltip className="show">
              <div>
                <b>Disponível: </b>
                {formatDecimal(props.data.stock_available)}
              </div>
              <div>
                <b>Total: </b>
                {formatDecimal(props.data.stock_managed)}
              </div>
            </Tooltip>
          }
        >
          <div>{formatDecimal(props.data.stock_available)}</div>
        </OverlayTrigger>
      </td>
      {/* Compra em Aberto */}
      <td>
        <div className="position-relative">
          {formatDecimal(props.data.wght_avg_price, true)}
          <br />
          {formatDecimal(props.data.total_pndg_qtt)}
          {props.data.num_level == 5 && (
            <Button className="rounded-circle info-compradores" size="sm" onClick={props.onModalBuyers}>
              <FaInfo />
            </Button>
          )}
        </div>
      </td>

      {/* Tendência de Demanda */}
      {props.data.amount_sold_lag3_trend || props.data.amount_sold_lag2_trend || props.data.amount_sold_lag1_trend ? (
        <td>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="show">
                <div>
                  <b>S-3: </b> {formatDecimal(props.data.amount_sold_lag3_trend, false)}
                </div>
                <div>
                  <b>S-2: </b> {formatDecimal(props.data.amount_sold_lag2_trend, false)}
                </div>
                <div>
                  <b>S-1: </b> {formatDecimal(props.data.amount_sold_lag1_trend, false)}
                </div>
              </Tooltip>
            }
          >
            <div>
              <div>
                <b>S-2: </b>
                {tendenciaPreco(props.data.amount_sold_lag2_trend, props.data.amount_sold_lag3_trend)}
              </div>
              <div>
                <b>S-1: </b>
                {tendenciaPreco(props.data.amount_sold_lag1_trend, props.data.amount_sold_lag2_trend)}
              </div>
            </div>
          </OverlayTrigger>
        </td>
      ) : (
        <td>
          <div>
            <b>S-2: </b>
            {tendenciaPreco(props.data.amount_sold_lag2_trend, props.data.amount_sold_lag3_trend)}
          </div>
          <div>
            <b>S-1: </b>
            {tendenciaPreco(props.data.amount_sold_lag1_trend, props.data.amount_sold_lag2_trend)}
          </div>
        </td>
      )}

      {/* Tendência de Preço */}
      {props.data.wavg_price_lag3_trend && props.data.wavg_price_lag2_trend && props.data.wavg_price_lag1_trend ? (
        <td>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="show">
                <div>
                  <b>S-3: </b> {formatDecimal(props.data.wavg_price_lag3_trend, true)}
                </div>
                <div>
                  <b>S-2: </b> {formatDecimal(props.data.wavg_price_lag2_trend, true)}
                </div>
                <div>
                  <b>S-1: </b> {formatDecimal(props.data.wavg_price_lag1_trend, true)}
                </div>
              </Tooltip>
            }
          >
            <div>
              <div>
                <b>S-2: </b>
                {tendenciaPreco(props.data.wavg_price_lag2_trend, props.data.wavg_price_lag3_trend)}
              </div>
              <div>
                <b>S-1: </b>
                {tendenciaPreco(props.data.wavg_price_lag1_trend, props.data.wavg_price_lag2_trend)}
              </div>
            </div>
          </OverlayTrigger>
        </td>
      ) : (
        <td>
          <div>
            <b>S-2: </b> {tendenciaPreco(props.data.wavg_price_lag2_trend, props.data.wavg_price_lag3_trend)}{" "}
          </div>
          <div>
            <b>S-1: </b> {tendenciaPreco(props.data.wavg_price_lag1_trend, props.data.wavg_price_lag2_trend)}{" "}
          </div>
        </td>
      )}

      {/* Margem do Tendencia de Preço */}
      {/* <td>{margemTr(props.data.margin_executed_price)}</td> */}

      {/* Volume Vendido */}
      <td>{formatDecimal(props.data.amount_sold)}</td>

      {/* Preço PMZ */}
      <td>{formatDecimal(props.data.price_zero_margin, true)}</td>

      {/* Preço do Concorrente */}
      {props.data.competitor_mode_price || props.data.competitor_median_price ? (
        <td>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="show">
                <div>
                  <b>Moda: </b> {formatDecimal(props.data.competitor_mode_price, true)}
                </div>
                <div>
                  <b>Mediana: </b> {formatDecimal(props.data.competitor_median_price, true)}
                </div>
              </Tooltip>
            }
          >
            <div>
              {formatDecimal(props.data.competitor_mode_price, true)} <br />
              {formatDecimal(props.data.competitor_median_price, true)}
            </div>
          </OverlayTrigger>
        </td>
      ) : (
        <td>
          {formatDecimal(props.data.competitor_mode_price, true)} <br />
          {formatDecimal(props.data.competitor_median_price, true)}
        </td>
      )}

      {/* Preço Tabela Atual */}
      <td>{formatDecimal(props.data.price_table, true)}</td>

      {/* Margem do Preço Atual */}
      {props.data.price_table || props.data.price_zero_margin ? (
        <td>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="show">
                <b>Preço Tabela: </b> {formatDecimal(props.data.price_table, true)} <br />
                <b>PMZ: </b> {formatDecimal(props.data.price_zero_margin, true)}
              </Tooltip>
            }
          >
            <div>{margemPreco(props.data.price_table, props.data.price_zero_margin)}</div>
          </OverlayTrigger>
        </td>
      ) : (
        <td>{margemPreco(props.data.price_table, props.data.price_zero_margin)}</td>
      )}

      {/* Demanda IA (S+1) */}
      <td>{props.data.num_level < 5 && formatDecimal(props.data.demand_forecast, false)} </td>

      {/* Preço Sugerido IA */}
      <td>{formatDecimal(props.data.new_price_optimizer, true)}</td>

      {/* Variação de Preço */}
      {props.data.new_price_optimizer || props.data.price_zero_margin ? (
        <td>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="show">
                <b>Preço IA: </b> {formatDecimal(props.data.new_price_optimizer, true)} <br />
                <b>PMZ: </b> {formatDecimal(props.data.price_zero_margin, true)}
              </Tooltip>
            }
          >
            <div>{margemPreco(props.data.new_price_optimizer, props.data.price_zero_margin)}</div>
          </OverlayTrigger>
        </td>
      ) : (
        <td> {margemPreco(props.data.new_price_optimizer, props.data.price_zero_margin)}</td>
      )}

      {/* Automatizar */}
      <td className="text-center">
        {(props.data.branch_type == "Distribuidora" ? props.data.num_level >= 4 : props.data.num_level >= 3) ? (
          <SwitchItem
            disabled={isSalved || desabilitarEdicao()}
            checked={props.data.is_automated}
            onChange={(event) => automateChange(event, props.data, props.index)}
          />
        ) : (
          "-"
        )}
      </td>

      {/* Aceitar */}
      <td className="text-center">
        {(props.data.branch_type == "Distribuidora" ? props.data.num_level >= 4 : props.data.num_level >= 3) ? (
          <label className="checkbox">
            <input
              type="checkbox"
              id={"checkbox" + props.index}
              disabled={props.data.is_automated || isSalved || desabilitarEdicao()}
              checked={props.data.is_accepted || props.data.is_automated}
              onChange={(event) => {
                isAcceptedChange(event.currentTarget.checked, props.index);
              }}
            />
            <span className="checkmark"></span>
          </label>
        ) : (
          "-"
        )}
      </td>

      {/* Editar Preço */}
      <td className="text-center">
        <div className="position-relative">
          {(props.data.branch_type == "Distribuidora" ? props.data.num_level >= 3 : props.data.num_level >= 3) ? (
            <div className="position-relative">
              <NumberFormat
                disabled={props.data.is_automated || props.data.is_accepted || isSalved || desabilitarEdicao() || salvando}
                type="tel"
                className="form-control"
                decimalSeparator={","}
                thousandSeparator={"."}
                prefix={"R$ "}
                decimalScale={2}
                fixedDecimalScale={true}
                id={"numberFormat" + props.index + props.data.product_code + props.data.mesorregion_code}
                value={props.data.price_edit || ""}
                onValueChange={(event) => {
                  const price_edit = Math.round(((props.data.price_edit || 0) + Number.EPSILON) * 100) / 100;
                  if (event.floatValue != price_edit && props.data.price_save != price_edit && !(!props.data.price_edit && !event.floatValue)) {
                    props.data.edit_price_save = event.floatValue ? event.floatValue : 0;
                    savePriceEdit();
                  }
                }}
                maxLength={14}
              />
              <span className="input-icon">
                <FaSave />
              </span>
            </div>
          ) : (
            formatDecimal(props.data.price_edit, true)
          )}
          <Button disabled={!props.data.price_edit} className="rounded-circle info-compradores top-2" size="sm" onClick={props.onModalEdit}>
            <FaInfo />
          </Button>
        </div>
      </td>

      {/* Δ Preço Editado X PMZ */}
      {props.data.price_edit || props.data.price_zero_margin ? (
        <td>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="show">
                <b>Preço Editado: </b> {formatDecimal(props.data.price_edit, true)} <br />
                <b>PMZ: </b> {formatDecimal(props.data.price_zero_margin, true)}
              </Tooltip>
            }
          >
            <div>{margemPreco(props.data.price_edit, props.data.price_zero_margin)}</div>
          </OverlayTrigger>
        </td>
      ) : (
        <td> {margemPreco(props.data.price_edit, props.data.price_zero_margin)}</td>
      )}
      {/* Δ Preço Editado X IA */}
      {props.data.price_edit || props.data.new_price_optimizer ? (
        <td>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="show">
                <b>Preço Editado: </b> {formatDecimal(props.data.price_edit, true)} <br />
                <b>Preço IA: </b> {formatDecimal(props.data.new_price_optimizer, true)}
              </Tooltip>
            }
          >
            <div>{margemPreco(props.data.price_edit, props.data.new_price_optimizer)}</div>
          </OverlayTrigger>
        </td>
      ) : (
        <td> {margemPreco(props.data.price_edit, props.data.new_price_optimizer)}</td>
      )}
      {/* Editar Minimo */}
      <td className="text-center">
        <div className="position-relative">
          {(props.data.branch_type == "Distribuidora" ? props.data.num_level >= 3 : props.data.num_level >= 3) ? (
            <div className="position-relative">
              <NumberFormat
                // disabled={props.data.is_automated || props.data.is_accepted || isSalved || desabilitarEdicao()}
                disabled={isMinSalved || desabilitarEdicao() || salvando}
                type="tel"
                className="form-control"
                decimalSeparator={","}
                thousandSeparator={"."}
                prefix={"R$ "}
                decimalScale={2}
                fixedDecimalScale={true}
                value={props.data.price_minimum || ""}
                id={"numberFormatMin" + props.index + props.data.product_code + props.data.mesorregion_code}
                onValueChange={(event) => {
                  const price_minimum = Math.round(((props.data.price_minimum || 0) + Number.EPSILON) * 100) / 100;
                  if (event.floatValue != price_minimum && props.data.price_save != price_minimum && !(!props.data.price_minimum && !event.floatValue)) {
                    props.data.minimum_price_save = event.floatValue ? event.floatValue : 0;
                    savePriceMinimum();
                  }
                }}
                maxLength={14}
              />
              <span className="input-icon">
                <FaSave />
              </span>
              {/* </> 
}*/}
            </div>
          ) : (
            formatDecimal(props.data.price_minimum, true)
          )}
          {/* <Button disabled={!props.data.price_minimum} className="rounded-circle info-compradores top-2" size="sm" onClick={props.onModalEdit}>
            <FaInfo />
          </Button> */}
        </div>
      </td>
      {/* Δ Preço Minimo X PMZ */}
      {props.data.price_minimum || props.data.price_zero_margin ? (
        <td>
          <OverlayTrigger
            placement="top"
            overlay={
              <Tooltip className="show">
                <b>Preço Mínimo: </b> {formatDecimal(props.data.price_minimum, true)} <br />
                <b>PMZ: </b> {formatDecimal(props.data.price_zero_margin, true)}
              </Tooltip>
            }
          >
            <div>{margemPreco(props.data.price_minimum, props.data.price_zero_margin)}</div>
          </OverlayTrigger>
        </td>
      ) : (
        <td> {margemPreco(props.data.price_minimum, props.data.price_zero_margin)}</td>
      )}
    </>
  );
};
