import React, { createContext, useState, useContext } from "react";

interface ToastData {
  title: string;
  subTitle: string;
  type: "success" | "warning" | "info"|"error";
 
}
// O que o contexto irá passar para o outros componentes
interface ToastContextData {
  toast: ToastData | null;
  toastShow(toast: ToastData): void;
  toastClear(): void;
}

// Como o objeto de contexto vai iniciar
const ToastContext = createContext<ToastContextData>({} as ToastContextData);


type Props = {
  children?: JSX.Element,
};
// Componente de contexto que irá por volta de todos os outros componentes
export const ToastProvider: React.FC<Props> = ({ children })  => {
  const [toast, setToast] = useState<ToastData | null>(null);
  async function toastShow(event: ToastData) {
    setToast(event);
  }
  async function toastClear() {
    setToast(null);
  }

  return (
    <ToastContext.Provider value={{ toast, toastShow, toastClear }}>
      {children}
    </ToastContext.Provider>
  );
};

export function useToast() {
  const context = useContext(ToastContext);

  return context;
}
