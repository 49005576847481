import * as React from "react";

import { Card, Container } from "react-bootstrap";
import loadingGif from "../../assets/imagens/loading.gif";
import { useToast } from "../../core/contexts/toast";
import { useEffect, useState } from "react";
import ChartDemandForecast from "./chart";
import { MessageType } from "../../core/config/message-type";
import { Filter } from "./filter";
import "./index.scss";
import { DemandForecastModel } from "../../core/model/demand_forecast";
import { FilterDemandForecast, DemandForecastRepository } from "../../core/repositories/demand_forecast.repository";


var count: number = 0;

const DemandForecast: React.FC = () => {
  const { toastShow } = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<DemandForecastModel[]>([]);
  const [search, setSearch] = useState(new FilterDemandForecast());
  const [compare, setCompare] = useState(false);

  const _DemandForecast = new DemandForecastRepository();

  useEffect(() => {
    getList(search);
  }, []);

  function getList(search: FilterDemandForecast) {
    search.page = 1;
    setSearch({ ...search });
    loadingList(search);
  }

  function changeSort(searchA: FilterDemandForecast) {
    setSearch({ ...searchA });
    if (search.cluster != searchA.cluster) {
      loadingList(searchA);
    } else {
      setLoading(true);
      setTimeout(() => {
        setLoading(false);
      }, 100);
    }
  }

  function loadingList(search: FilterDemandForecast) {
    const _count = count++;
    // setLoading(true);
    setLoading(true);
    _DemandForecast.getFilter(search).then((data) => {
      if (_count === count - 1) {
        setSearch({ ...search });
        setData(data);
        setLoading(false);
      }
    }).catch(() => {
      toastShow({
        title: "Erro!",
        subTitle: MessageType.ERROR_TRY,
        type: "error",
      });
    });
  }




  return (
    <>
      <Container className="DemandForecast max-98 pb-4">
        <Filter search={search} onChangeFilter={getList}  onChangeCompare={() => { setCompare(!compare) }} />

        <Card className="card-d">
          <Card.Body>
            {loading ? (
              <div className="height-500">
                <div className="text-center loading align-vertical  ">
                  <img className="width-150 height-150" src={loadingGif} alt="" />
                </div>
              </div>
            ) : (
              <ChartDemandForecast
                search={search}
                onChangeSort={changeSort}
                // onUpdateData={(dataUp: DemandForecastModel) => {
                //   setData({ ...dataUp });
                // }}
                data={data}
              />
            )}
          </Card.Body>
        </Card>
      </Container>
      {
        compare  && <DemandForecast />
      }
    </>
  );
};

export default DemandForecast;
