import * as React from 'react';
import moment from 'moment';
import { Button, Table } from 'react-bootstrap';
import TableRow from '../../components/TableRow';
import TablePagination from '../../components/TablePagination';
import { FormatType } from '../../core/config/format-type';
import { useState } from 'react';
import { ModalConfirm } from '../../components/ModalConfirm/indes';
import { FaEdit, FaUndoAlt, FaTrash } from 'react-icons/fa'

import { ModalCNPJHash } from './modal';
import { CNPJHashModel } from '../../core/model/cnpj_hash';
import { FilterCNPJHash, CNPJHashRepository } from '../../core/repositories/cnpj_hash.repository';

interface Props {
	search: FilterCNPJHash;
	data: CNPJHashModel[];
	onChangeSort: (search: FilterCNPJHash) => void;
	onUpdateData: (data: CNPJHashModel[]) => void;
}

const TableCNPJHash: React.FC<Props> = (props) => {
	const [selectCNPJHash, setSelecetCNPJHash] = useState<CNPJHashModel>();
	const [modalShowEdit, setModalShowEdit] = useState(false);
	const [modalShowActive, setModalShowActive] = useState(false);

	const _cnpj = new CNPJHashRepository();

	function changeSort(value: string, prop: string) {
		const aux: any = {};
		aux[prop] = value;
		props.search.sort = aux;
		props.onChangeSort(props.search);
	}
	function changeActive() {
		if (selectCNPJHash) {
			_cnpj.changeIsActive(selectCNPJHash.id, !selectCNPJHash.active).then((data) => {
				selectCNPJHash.active = !selectCNPJHash.active;
				props.onUpdateData(props.data.filter((x) => x.id != selectCNPJHash.id));
			});
			setModalShowActive(false);
		}
	}

	return (
		<>
			<Table bordered hover responsive borderless size="sm">
				<thead>
					<tr>
						<TableRow
							sort={props.search.sort.description}
							onSort={(ev: any) => changeSort(ev, 'full_name')}>
							CNPJ
						</TableRow>

						<TableRow>Status</TableRow>

						<TableRow sort={props.search.sort.date_creation} onSort={(ev: any) => changeSort(ev, 'date_creation')}>
							Cadastrado em
						</TableRow>
						<TableRow>Ação</TableRow>
					</tr>
				</thead>
				<tbody>
					{props.data.length === 0 && (
						<tr className="table-row-header text-center">
							<td colSpan={14}> Nenhum registro encontrado! </td>
						</tr>
					)}
					{props.data.map((_, index) => (
						<tr className="text-center" key={index}>
							{/* CNPJ */}
							<td className="text-capitalize">{_.description}</td>
							
							{/* Status */}
							<td>{_.active ? 'Ativo' : 'Inativo'}</td>
							{/* Creation Date */}
							<td>{moment(_.date_creation).format(FormatType.MOMENT_FORMAT)}</td>
							{/* Action */}
							<td>
								{_.active && (
									<Button
										variant="outline-primary"
										size="sm"
										onClick={() => {
											setSelecetCNPJHash(_);
											setModalShowEdit(true);
										}}
									>
										<FaEdit />
									</Button>
								)}
								<Button
									variant={_.active ? 'outline-danger' : 'outline-success'}
									size="sm"
									onClick={() => {
										setSelecetCNPJHash(_);
										setModalShowActive(true);
									}}
								>
									{_.active ? <FaTrash /> : <FaUndoAlt />}
								</Button>
							</td>
						</tr>
					))}
				</tbody>
			</Table>

			<div>
				<TablePagination
					onChangePage={(value) => {
						props.search.page = value;
						props.onChangeSort(props.search);
					}}
					currentPage={props.search.page}
					pageSize={props.search.pageSize}
					count={props.search.total}
				/>
			</div>
			<ModalConfirm
				title={`Confirma se deseja ${selectCNPJHash?.active ? 'remover' : 'liberar'} o usuário ?`}
				subTitle={`Você deseja ${selectCNPJHash?.active ? 'remover' : 'liberar'} "${selectCNPJHash?.description}" do sistema?`}
				show={modalShowActive}
				onHide={() => {
					setSelecetCNPJHash(undefined);
					setModalShowActive(false);
				}}
				confirm={changeActive}
			/>

			<ModalCNPJHash
				show={modalShowEdit}
				cnpj={selectCNPJHash}
				onRefresh={() => {
					props.onChangeSort(props.search);
				}}
				onHide={() => {
					setModalShowEdit(false);
				}}
			/>
		</>
	);
};

export default TableCNPJHash;
