import * as React from 'react';
import { useEffect, useState } from 'react';
import { Container, Card, Button, Spinner } from 'react-bootstrap';
import { useToast } from '../../core/contexts/toast';
import { Filter } from './filter';
import { FaDownload } from 'react-icons/fa';
import loadingGif from '../../assets/imagens/loading.gif';
import { MessageType } from '../../core/config/message-type';
import { CustomerSegmentationModel } from '../../core/model/customer_segmentation';
import { FilterCustomerSegmentation, CustomerSegmentationRepository } from '../../core/repositories/customer_segmentation.repository';
import { TableCustomerSegmentation } from './table';
import './index.scss';

var count: number = 0;

export const CustomerSegmentation: React.FC = () => {
	const [loading, setLoading] = useState(true);
	const { toastShow } = useToast();
	const [search, setSearch] = useState(new FilterCustomerSegmentation());
	const [loadingBtnDownload, setLoadingBtnDownload] = useState(false);
	const [data, setData] = useState<CustomerSegmentationModel[]>([]);
	const [hideCustomer, setHideCustomer] = useState(false);

	const _customerSegmentationRepository = new CustomerSegmentationRepository();
	
	// useEffect(() => {
	// 	getList(search);
	// }, []);

	useEffect(() => {
		search.hide_customer = hideCustomer;
		getList(search);
	}, [hideCustomer]);

	function getList(search: FilterCustomerSegmentation) {
		search.page = 1;
		if (search.hide_customer) {
			search.customer_code = '';
			search.city = '';
		}
		setSearch({ ...search });
		loadingList(search);
	}
	function changeSort(search: FilterCustomerSegmentation) {
		setSearch({ ...search });
		loadingList(search);
	}

	function loadingList(search: FilterCustomerSegmentation) {
		const _count = count++;
		setLoading(true);

		_customerSegmentationRepository
			.getFilter(search)
			.then((data) => {
				if (_count === count - 1) {
					search.total = data.length ? data[0].total_records : data.length;
					setSearch({ ...search });

					setData(data);
					setLoading(false);
				}
			})
			.catch(() => {
				setLoading(false);
				toastShow({
					title: 'Erro!',
					subTitle: MessageType.ERROR_TRY,
					type: 'error',
				});
			});
	}

	function download() {
		setLoadingBtnDownload(true);
		_customerSegmentationRepository.download(search).then((req) => {
			var blob = new Blob([req.data], {
				type: req.headers['content-type'],
			});
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = `customer_segmentation_${new Date().toISOString()}.xlsx`;
			link.click();
			setLoadingBtnDownload(false);
		}).catch(() => {
			setLoadingBtnDownload(false);
			toastShow({
				title: 'Erro!',
				subTitle: MessageType.ERROR_TRY,
				type: 'error',
			});
		});
	}

	return (
		<Container className="CustomerSegmentation max-98">
			<Filter search={search} onChangeFilter={getList} />
			{/* <Button className="download-bnt" disabled={loadingBtnDownload} onClick={download} variant="secondary">
				{loadingBtnDownload ? <Spinner size="sm" animation="border" role="status" /> : <FaDownload />}
			</Button> */}
			<Card className="card-d">
				<Card.Body>
					{loading ? (
						<div className="height-500">
							<div className="text-center loading align-vertical  ">
								<img className="width-150 height-150" src={loadingGif} alt="" />
							</div>
						</div>
					) : (
						<TableCustomerSegmentation search={search} onChangeSort={changeSort} data={data} />
					)}
				</Card.Body>
			</Card>
		</Container>
	);
};
