import React from "react";
import { useAuth } from "../../core/contexts/auth";
import { Spinner } from "react-bootstrap";
import { useToast } from "../../core/contexts/toast";
import { useNavigate } from "react-router-dom";

export const Logout: React.FC = () => {
    const { toastShow } = useToast();
    const { clearUserState, signOut, getUserAzure } = useAuth();
    const navigate = useNavigate();

    React.useEffect(() => {
        try {
            clearUserState();
            getUserAzure().then(azure => {
                signOut(azure != null);
            });
        } catch (error: any) {
            alert(typeof error === "string" ? error : error.message)
            toastShow({
                title: "Erro!",
                subTitle: typeof error === "string" ? error : error.message,
                type: "error",
            });
            navigate("/login");
        }
    }, []);

    return (
        <div style={{
            position: 'absolute', left: '50%', top: '50%',
            transform: 'translate(-50%, -50%)'
        }}>
            <Spinner animation="border" variant="primary" />
        </div>
    )
}