import { BrowserRouter } from 'react-router-dom';
import { AuthProvider } from './core/contexts/auth';
import { ToastProvider } from './core/contexts/toast';
import Routes from './routes';
import React from 'react';
import ErrorBoundary from './components/ErrorBoundary';
import { ModalSelectProvider } from './core/contexts/modalSelectContext';
import "ag-grid-community/styles/ag-grid.css"; // Mandatory CSS required by the grid
import "ag-grid-community/styles/ag-theme-quartz.css"; // Optional Theme applied to the grid
import './App.scss';



const App: React.FC = () => {
	return (
		<AuthProvider>
			<ToastProvider>
				<ModalSelectProvider>
				<BrowserRouter>
					<ErrorBoundary>
						<Routes />
					</ErrorBoundary>
				</BrowserRouter>
				</ModalSelectProvider>
			</ToastProvider>
		</AuthProvider>
	);
};

export default App;
