import * as React from 'react';
import { Row, Col, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useToast } from '../../core/contexts/toast';
import { useState, useEffect, FC } from 'react';
import { MessageType } from '../../core/config/message-type';
import { FilterDemandVariability } from '../../core/repositories/demand_variability.repository';
import { ProductDepartmentModel } from '../../core/model/product_department';
import { ProductSectionModel } from '../../core/model/product_section';
import { ProductCategoryModel } from '../../core/model/product_category';
import { ProductModel } from '../../core/model/product';
import { CompetitorModel } from '../../core/model/competitor';
import { ProductDepartmentRepository } from '../../core/repositories/product_department.repository';
import { ProductSectionRepository } from '../../core/repositories/product_section.repository';
import { ProductCategoryRepository } from '../../core/repositories/product_category.repository';
import { ProductRepository } from '../../core/repositories/product.repository';
import { CompetitorRepository } from '../../core/repositories/competitor.repository';
import { getNameFilter } from '../../core/utils/functions';
import { FilterHeader } from '../../components/FilterHeader';
import SelectSearch from '../../components/SelectSearch';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from "react-datepicker";


interface Props {
	search: FilterDemandVariability;
	onChangeFilter: (search: FilterDemandVariability) => void;
}

export const Filter: React.FC<Props> = (props) => {
	const { toastShow } = useToast();

	const [productDepartmentList, setProductDepartmentList] = useState<ProductDepartmentModel[]>([]);
	const [productSectionList, setProductSectionList] = useState<ProductSectionModel[]>([]);
	const [productCategoryList, setProductCategoryList] = useState<ProductCategoryModel[]>([]);
	const [productList, setProductList] = useState<ProductModel[]>([]);

	const [productDepartmentShowList, setProductDepartmentShowList] = useState<ProductDepartmentModel[]>([]);
	const [productSectionShowList, setProductSectionShowList] = useState<ProductSectionModel[]>([]);
	const [productCategoryShowList, setProductCategoryShowList] = useState<ProductCategoryModel[]>([]);
	const [productShowList, setProductShowList] = useState<ProductModel[]>([]);

	const [competitorList, setCompetitorList] = useState<CompetitorModel[]>([]);

	const [startDate, setStartDate] = useState(props.search.date_start);
	const [endDate, setEndDate] = useState(props.search.date_end);

	useEffect(() => {
		try {
			new ProductDepartmentRepository().getAll().then((res) => {
				setProductDepartmentList(res);
				setProductDepartmentShowList(res);
			});
			new ProductSectionRepository().getAll().then((res) => {
				setProductSectionList(res);
				setProductSectionShowList(res);
			});
			new ProductCategoryRepository().getAll().then((res) => {
				setProductCategoryList(res);
				setProductCategoryShowList(res);
			});
			new ProductRepository().getAll().then((res) => {
				setProductList(res);
				setProductShowList(res);
			});
			new CompetitorRepository().getAll().then((res) => {
				setCompetitorList(res);
			});
		} catch (error) {
			toastShow({
				title: 'Erro!',
				subTitle: MessageType.ERROR_TRY,
				type: 'error',
			});
		}
	}, []);

	useEffect(() => {
		var _sectionList = productSectionList;
		var _categoryList = productCategoryList;
		var _productList = productList;

		if (props.search.department_id) {
			_sectionList = productSectionList.filter((item) => props.search.department_id.split(',').includes(item.department_id.toString()));
			_categoryList = _categoryList.filter((item) => _sectionList.map((i) => i.id).includes(item.section_id));
			_productList = _productList.filter((item) => _categoryList.map((i) => i.id).includes(item.category_id));
		}
		if (props.search.section_id) {
			_categoryList = _categoryList.filter((item) => props.search.section_id.split(',').includes(item.section_id.toString()));
			_productList = _productList.filter((item) => _categoryList.map((i) => i.id).includes(item.category_id));
		}
		if (props.search.category_id) {
			_productList = _productList.filter((item) => item.category_id && props.search.category_id.split(',').includes(item.category_id.toString()));
		}
		setProductSectionShowList(_sectionList.sort((a, b) => a.description?.localeCompare(b.description)));
		setProductCategoryShowList(_categoryList.sort((a, b) => a.description?.localeCompare(b.description)));
		setProductShowList(_productList.sort((a, b) => a.description?.localeCompare(b.description)));
	}, [props.search]);

	function handleClearFilter() {
		var selects = document.getElementsByTagName('select');
		for (var i = 0; i < selects.length; i++) {
			selects[i].value = '';
		}
		let _filter = new FilterDemandVariability();
		props.onChangeFilter(_filter);
	}

	return (
		<div className="stick">
			<FilterHeader
				title="Demand Variability"
				clearFilter={handleClearFilter}
				reload={() => {
					props.onChangeFilter(props.search);
				}}
			>
				<Row>
					<Col className="font-size-12" md={2}>
						<SelectSearch
							name="Departamento"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.department_id}
							options={productDepartmentShowList}
							onChange={(value) => {
								value = value.toString();
								props.search.department_id = value;
								props.search.department_description = getNameFilter(productDepartmentList, value, 'id', 'description');
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col className="font-size-12" md={2}>
						<SelectSearch
							name="Seção"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.section_id}
							options={productSectionShowList}
							onChange={(value) => {
								value = value.toString();
								props.search.section_id = value;
								props.search.section_description = getNameFilter(productSectionList, value, 'id', 'description');
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col className="font-size-12" md={2}>
						<SelectSearch
							name="Categoria"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.category_id}
							options={productCategoryShowList}
							onChange={(value) => {
								value = value.toString();
								props.search.category_id = value;
								props.search.category_description = getNameFilter(productCategoryList, value, 'id', 'description');
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col className="font-size-12" md={2}>
						<SelectSearch
							name="Produto"
							selectId="id"
							selectName="description_show"
							multiple={true}
							value={props.search.product_id}
							options={productShowList}
							onChange={(value) => {
								value = value.toString();
								props.search.product_id = value;
								props.search.product_description = getNameFilter(productList, value, 'id', 'description');
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>




					<Form.Group as={Col} className="mb-3" md={2}>
						<Form.Label className="font-size-14 form-label">
							Intervalo
						</Form.Label>

						<DatePicker
							className="form-control"
							selectsRange
							startDate={startDate}
							endDate={endDate}
							onChange={(dates: [Date | null, Date | null]) => {
								const [start, end] = dates;
								setStartDate(start);
								setEndDate(end);
							}}
						/>
					</Form.Group>

					<Form.Group as={Col} className="mb-3" md={2}>
						<Form.Label className="font-size-14 form-label">
							Valor
						</Form.Label>
						<Col md={12}>
							<ToggleButtonGroup
								value={props.search.value}
								type="radio"
								name="options1"
								className="w-100"
								onChange={(value) => {
									if (value) {
										props.search.value = value;
									}
									props.onChangeFilter(props.search);
								}}
							>
								<ToggleButton
									id="tbg-value-1"
									variant="outline-primary"
									value="Quantity"
									className="w-50"
								>
									Quantidade
								</ToggleButton>
								<ToggleButton
									id="tbg-value-2"
									variant="outline-primary"
									value="Revenue"
									className="w-50"
								>
									Receita
								</ToggleButton>
							</ToggleButtonGroup>
						</Col>
					</Form.Group>
				</Row>
			</FilterHeader>
		</div>
	);
};
