

export enum UserType {

  DEV = 0,
  ADMINISTRADOR = 1,
  INTERNO = 2,
  EXTERNO = 3

}



