import { CollecionName } from '../config/collection-name';
import { BasicFilter } from '../model/base-model';
import { DemandForecastModel } from '../model/demand_forecast';
import { Repository } from './repository';

export class FilterDemandForecast extends BasicFilter<DemandForecastModel> {

	distribution_id = "";
	product_category_id = "";
	department_id = "";
	section_id = "";
	category_id = "";
	product_id = "";
	department_description = "";
	section_description = "";
	category_description = "";
	product_description = "";
	
	cluster = '';
	absolute: boolean = false;
	type: string = 'retention_client';

	scale = "d";
	value: "Revenue" | "Quantity" = "Quantity";
	date_start!: any;
	date_end!: any;

	constructor() {
		super();
		this.date_start = new Date(
			new Date().getFullYear(),
			new Date().getMonth() - 1,
			1
		);
		this.date_end = new Date(
			new Date().getFullYear(),
			new Date().getMonth() + 2,
			0
		);
	}

}

export class DemandForecastRepository extends Repository<DemandForecastModel> {
	constructor() {
		super(CollecionName.DEMAND_FORECAST);
	}

	public getFilterAny(data: any): Promise<DemandForecastModel> {
		return super.getFilter(data).then((res: any) => {
			return res;
		});
	}


}
