import * as React from 'react';
import { Table } from 'react-bootstrap';
import TablePagination from '../../components/TablePagination';
import TableRow from '../../components/TableRow';
import { formatDecimal } from '../../core/utils/functions';
import { LogApiModel } from '../../core/model/log_api';
import { FilterLogApi } from '../../core/repositories/log_api.repository';
import moment from 'moment';

interface Props {
	search: FilterLogApi;
	data: LogApiModel[];
	onChangeSort: (search: FilterLogApi, loading: boolean) => void;
}

export const TableLogApi: React.FC<Props> = (props) => {
	function changeSort(value: string, prop: string) {
		const aux: any = {};
		aux[prop] = value;
		props.search.sort = aux;
		props.onChangeSort(props.search, true);
	}


	return (
		<>
			{/* {props.data.length > 0 && (
				<div className="text-end font-size-15">
					Última atualização do modelo: <b>{moment(props.data[0].date).format('DD/MM/YYYY')}</b>
				</div>
			)} */}
			<Table striped bordered hover size="sm">
				<thead>
					<tr>
						<TableRow
							classNameTh='width-90'
							sort={props.search.sort.api_type}
							onSort={(ev: any) => changeSort(ev, 'api_type')}>
							Tipo Api
						</TableRow>
						<TableRow sort={props.search.sort.request_payload} onSort={(ev: any) => changeSort(ev, 'request_payload')}>
							Payload
						</TableRow>
						<TableRow
							classNameTh='width-90'
							sort={props.search.sort.client_ip_address}
							onSort={(ev: any) => changeSort(ev, 'client_ip_address')}>
							IP
						</TableRow>
						<TableRow
							classNameTh='width-90'
							sort={props.search.sort.elapsed_time_milliseconds}
							onSort={(ev: any) => changeSort(ev, 'elapsed_time_milliseconds')}>
							Tempo
						</TableRow>
						<TableRow
							classNameTh='width-120'
							sort={props.search.sort.record_count}
							onSort={(ev: any) => changeSort(ev, 'record_count')}>
							Quantidade
						</TableRow>
						<TableRow
							classNameTh='width-90'
							sort={props.search.sort.success}
							onSort={(ev: any) => changeSort(ev, 'success')}>
							Sucesso
						</TableRow>
						<TableRow sort={props.search.sort.error_message} onSort={(ev: any) => changeSort(ev, 'error_message')}>
							Mensagem
						</TableRow>
						<TableRow
							classNameTh='width-90'
							sort={props.search.sort.date_creation}
							onSort={(ev: any) => changeSort(ev, 'date_creation')}>
							Data
						</TableRow>
					</tr>
				</thead>
				<tbody>
					{props.data.length === 0 && (
						<tr className="table-row-header text-center">
							<td colSpan={8}> Nenhum registro encontrado! </td>
						</tr>
					)}
					{props.data.map((_, index) => (
						<tr className="text-center" key={index}>
							<td> {_.api_type} </td>
							<td> {_.request_payload} </td>
							<td> {_.client_ip_address} </td>
							<td> {_.elapsed_time_milliseconds} </td>
							<td> {_.record_count} </td>
							<td> {_.success ? 'Sim' : 'Não'} </td>
							<td> {_.error_message} </td>
							<td>
								{moment(_.date_creation).format('DD/MM/YYYY')} <br />
								{moment(_.date_creation).format('HH:mm:ss')}
							</td>
						</tr>
					))}
				</tbody>
			</Table>
			<div>
				<TablePagination
					onChangePage={(value) => {
						props.search.page = value;
						props.onChangeSort(props.search, true);
					}}
					currentPage={props.search.page}
					pageSize={props.search.pageSize}
					count={props.search.total}
				></TablePagination>
			</div>
		</>
	);
};
