import { UserType } from "../config/user-type";
import { BaseModel } from "./base-model";

export class UserModel extends BaseModel {
  name!: string;
  email!: string;
  active!: boolean;
  admin!: boolean;
  time_creation?: Date;
  user_id_creator?: number;
  user_type: UserType = UserType.EXTERNO;
  user_department?: string;
  user_branch?: string;
  user_category?: string;
}
