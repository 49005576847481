// @flow
import * as React from "react";
import { useEffect, useRef } from "react";
import Form from "react-bootstrap/esm/Form";
import "react-bootstrap-typeahead/css/Typeahead.css";
import "./index.scss";

interface Props {
  id?: string;
  description?: string;
  value?: string;
  type?: string ;
  onChange?: (value: string) => void;
}

export const SearchInput: React.FC<Props> = (props) => {
  const ref = useRef();
  const [value, setValue] = React.useState<string>();


  useEffect(() => {
    if (props.value == undefined || props.value != value){
      setValue(props.value);
    }
  }, [props.value]);


  useEffect(() => {
    const timeOutId = setTimeout(() => {
      if (props.onChange && value != undefined) {
        props.onChange(value);
      }
    }, 1000);
    return () => clearTimeout(timeOutId);
  }, [value]);


  return (
    <Form.Group >
      <Form.Label className='font-size-14'>{props.description}</Form.Label>
      <Form.Control
        id={props.id}
        type={props.type || "text"}
        placeholder="Todos"
        value={value || ""}
        onChange={event => setValue(event.target.value)}
      />
    </Form.Group>
  );
};