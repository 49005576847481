import * as React from "react";

import { Card, Container } from "react-bootstrap";
import loadingGif from "../../assets/imagens/loading.gif";
import { useToast } from "../../core/contexts/toast";
import { useEffect, useState } from "react";
import TableChartActiveCluster from "./table";
import { MessageType } from "../../core/config/message-type";

import "./index.scss";
import { FilterActiveCluster, ActiveClusterRepository } from "../../core/repositories/active_cluster.repository";
import { ActiveClusterModel } from "../../core/model/active_cluster";
import { Filter } from "./filter";

var count: number = 0;

const ChartActiveCluster: React.FC = () => {
  const { toastShow } = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<ActiveClusterModel[]>([]);
  const [search, setSearch] = useState(new FilterActiveCluster());

  const _ActiveCluster = new ActiveClusterRepository();

  useEffect(() => {
    getList(search);
  }, []);

  function getList(search: FilterActiveCluster) {
    search.page = 1;
    setSearch({ ...search });
    loadingList(search);
  }

  function changeSort(search: FilterActiveCluster) {
    setSearch({ ...search });
    loadingList(search);
  }

  function loadingList(search: FilterActiveCluster) {
    const _count = count++;
    setLoading(true);

    _ActiveCluster.getFilter(search).then((data) => {
      if (_count === count - 1) {
        search.total = data.length
          ? (data[0] as any).total_records
          : data.length;
        setSearch({ ...search });
        setData(data);
        setLoading(false);
      }
    }).catch(() => {
      
      setLoading(false);
      toastShow({
        title: "Erro!",
        subTitle: MessageType.ERROR_TRY,
        type: "error",
      });
    });

  }

  return (
    <Container className="ChartActiveCluster max-98 pb-4">
      <Filter search={search} onChangeFilter={getList} />

      <Card className="card-d">
        <Card.Body>
          {loading ? (
            <div className="height-500">
              <div className="text-center loading align-vertical  ">
                <img className="width-150 height-150" src={loadingGif} alt="" />
              </div>
            </div>
          ) : (
            <TableChartActiveCluster
              search={search}
              onChangeSort={changeSort}
              onUpdateData={(dataUp: ActiveClusterModel[]) => {
                setData([...dataUp]);
              }}
              data={data}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default ChartActiveCluster;
