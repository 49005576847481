import { CollecionName } from '../config/collection-name';
import { MatchingValidationsModel } from '../model/matching_validations';
import { Repository } from './repository';


export class FilterMatchingValidations {

	competitor_id !: string;
	competitor_product_id!: string;
	competitor_product_description!: string;
	match_rating !: string;
	match_rating_description = "";
	competitor_issuer_type !: string;
	competitor_issuer_type_description!: string;
	match_status = "";
	match_status_description = "";
	competitor_ncm!: string;
	product_id?: string;
	product_description?: string;
	is_similar?: boolean;


	produtoConcorrente?: string;

	sort: Partial<MatchingValidationsModel> = {
	};

	page = 1;
	pageSize = 20;
	total = 0;
	constructor() {
	}
}

export class MatchingValidationsRepository extends Repository<MatchingValidationsModel> {

	constructor() {
		super(CollecionName.MATCHING_VALIDATIONS);
	}


	reset(data: MatchingValidationsModel) {
		return this.api.post(`${this.collectionName}_reset`, data).then((res) => {
			return res.data;
		});
	}
	getLastUpdate() {
		return this.api.get(`${this.collectionName}_last_update`).then((res) => {
			return res.data[0].date_creation;
		});
	}
}
