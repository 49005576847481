import { CollecionName } from "../config/collection-name";
import { BasePriceCatalogModel } from "../model/base_price_catalog";
import { Repository } from "./repository";

export class FilterBasePriceCatalog {
  branch_code?: string;
  branch_type?: string;
  department_code?: string;
  category_code?: string;
  subcategory_code?: string;
  price_table_code?: string;
  product_code?: string;
  year_week: string = "";
  mesorregion_code?: string;
  flg_aberto = true;
  num_level = 0;
  cod_user?: number;
}

export class BasePriceCatalogRepository extends Repository<BasePriceCatalogModel> {
  constructor() {
    super(CollecionName.BASE_PRICE_CATALOG);
  }

  getYearWeekList() {
    return this.api.get(this.collectionName + "_year_week_list").then((res) => {
      return res.data;
    });
  }

  call() {
    return this.api.post(this.collectionName + "/call").then((res) => {
      return res.data;
    });
  }

  getModelLastTime(year_week: string) {
    return this.api.get(this.collectionName + "_model_last_time?year_week=" + year_week).then((res) => {
      return res.data;
    });
  }
}
