import * as React from 'react';
import { useEffect, useState } from 'react';
import { Container, Card, Button, Spinner } from 'react-bootstrap';
import { useToast } from '../../core/contexts/toast';
import { Filter } from './filter';
import { FaDownload } from 'react-icons/fa';
import { MessageType } from '../../core/config/message-type';
import { ProductMatchingModel } from '../../core/model/product_matching';
import { FilterProductMatching, ProductMatchingRepository } from '../../core/repositories/product_matching.repository';
import { TableProductMatching } from './table';
import loadingGif from '../../assets/imagens/loading.gif';
import './index.scss';

var count: number = 0;

export const ProductMatching: React.FC = () => {
	const { toastShow } = useToast();
	const [loading, setLoading] = useState(true);
	const [loadingBtnDownload, setLoadingBtnDownload] = useState(false);
	const [search, setSearch] = useState(new FilterProductMatching());
	const [data, setData] = useState<ProductMatchingModel[]>([]);
	const _productMatchingRepository = new ProductMatchingRepository();

	// useEffect(() => {
	// 	getList(search);
	// }, []);

	function getList(search: FilterProductMatching) {
		search.page = 1;
		setSearch({ ...search });
		loadingList(search);
	}
	function changeSort(search: FilterProductMatching) {
		setSearch({ ...search });
		loadingList(search);
	}

	function loadingList(search: FilterProductMatching) {
		const _count = count++;
		setLoading(true);
		_productMatchingRepository.getFilter(search).then((data) => {
			if (_count === count - 1) {
				search.total = data.length ? data[0].total_records : data.length;
				setSearch({ ...search });
				setData(data);
				setLoading(false);
			}
		}).catch(() => {
			setLoading(false);
			toastShow({
				title: 'Erro!',
				subTitle: MessageType.ERROR_TRY,
				type: 'error',
			});
		});
	}

	function download() {
		setLoadingBtnDownload(true);
		_productMatchingRepository.download(search).then((req) => {
			const link = document.createElement('a');
			link.href = req.data;
			link.download = `product_matching_${new Date().toISOString()}.xlsx`;
			link.click();
			setLoadingBtnDownload(false);
		}).catch(() => {
			setLoadingBtnDownload(false);
			toastShow({
				title: 'Erro!',
				subTitle: MessageType.ERROR_TRY,
				type: 'error',
			});
		});
	}
	function disabledRule() {
		if (loadingBtnDownload)
			return true;
		if (search.department_id || search.section_id || search.category_id || search.product_id || search.match_rating || search.competitor_id || search.competitor_product_id || search.match_status || search.match_status_description)
			return false;
		return true;
	}

	return (
		<Container className="ProductMatching max-98">
			<Filter search={search} onChangeFilter={getList} />
			<Button className="download-bnt" disabled={disabledRule()} onClick={download} variant="secondary">
				{loadingBtnDownload ? <Spinner size="sm" animation="border" role="status" /> : <FaDownload />}
			</Button>
			<Card className="card-d">
				<Card.Body>
					{loading ? (
						<div className="height-500">
							<div className="text-center loading align-vertical  ">
								<img className="width-150 height-150" src={loadingGif} alt="" />
							</div>
						</div>
					) :
						<TableProductMatching search={search} onChangeSort={changeSort} data={data} />
					}
				</Card.Body>
			</Card>
		</Container>
	);
};
