import { CollecionName } from "../config/collection-name";
import { PurchaseOrderModel } from "../model/pruchase_order";
import { Repository } from "./repository";

export class FilterPurchaseOrder {
  branch_code?: number;
  department_code?: number;
  category_code?: number;
  subcategory_code?: string;
  mesorregion_code?: number;
  product_code?: number;
  year_week: string = "";
}

export class PurchaseOrderRepository extends Repository<PurchaseOrderModel> {
  constructor() {
    super(CollecionName.PURCHASE_ORDER);
  }
}
