export class BaseModel {
  id!: number;
  description?: string;
  time_created?: Date;
  total_records: number = 0;
  date_creation?: Date;
}


export abstract class BasicFilter<T> {
  sort: Partial<T> = {};

  page = 1;
  pageSize = 20;
  total: number = 0;
}