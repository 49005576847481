import React from "react";
import { useAuth } from "../../core/contexts/auth";
import { Spinner } from "react-bootstrap";
import { useToast } from "../../core/contexts/toast";
import { MessageType } from "../../core/config/message-type";
import { useNavigate } from "react-router-dom";

export const LoginSuccess: React.FC = () => {
  const { toastShow } = useToast();
  const { getUserAzure, getInfoByEmail, signOut, clearUserState } = useAuth();

  const navigate = useNavigate();

  React.useEffect(() => {
    try {
      getUserAzure().then(async (res) => {
        if (res) {
          const user = await getInfoByEmail(res.email);

          if (user) {
            // navigate("/matching-validations");
            navigate("/base-price");
          } else {
            alert(MessageType.ERROR);
            navigate("/logout");
          }
        } else {
          clearUserState();
          signOut(true);
        }
      });
    } catch (error: any) {
      alert(typeof error === "string" ? error : error.message);
      toastShow({
        title: "Erro!",
        subTitle: typeof error === "string" ? error : error.message,
        type: "error",
      });
      navigate("/login");
    }
  }, []);

  return (
    <div
      style={{
        position: "absolute",
        left: "50%",
        top: "50%",
        transform: "translate(-50%, -50%)",
      }}
    >
      <Spinner animation="border" variant="primary" />
    </div>
  );
};
