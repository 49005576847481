import { CollecionName } from '../config/collection-name';
import { DemandVariabilityModel } from '../model/demand_variability';
import { Repository } from './repository';

export class FilterDemandVariability {
	distribution_id = "";
	product_category_id = "";
	department_id = "";
	section_id = "";
	category_id = "";
	product_id = "";
	department_description = "";
	section_description = "";
	category_description = "";
	product_description = "";
	
	value: "Revenue" | "Quantity" = "Quantity";
	date_start!: any;
	date_end!: any;
  
	sort: Partial<DemandVariabilityModel> = {
	  // distribution_center: 'asc',
	};
  
	page = 1;
	pageSize = 20;
	total = 0;
  
	constructor() {
	  this.date_start = new Date(
		new Date().getFullYear(),
		new Date().getMonth() - 1,
		1
	  );
	  this.date_end = new Date(
		new Date().getFullYear(),
		new Date().getMonth() + 2,
		0
	  );
	}
  }
  
  export class DemandVariabilityRepository extends Repository<DemandVariabilityModel> {
	constructor() {
	  super(CollecionName.DEMAND_VARIABILITY);
	}
  }
  