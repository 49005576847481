import * as React from "react";
import moment from "moment";
import { Button, Table } from "react-bootstrap";
import TableRow from "../../components/TableRow";
import TablePagination from "../../components/TablePagination";
import { UserModel } from "../../core/model/user";
import { FilterUser, UserRepository } from "../../core/repositories/user.repository";
import { FormatType } from "../../core/config/format-type";
import { useState } from "react";
import { ModalConfirm } from "../../components/ModalConfirm/indes";
import { FaEdit, FaUndoAlt, FaTrash } from "react-icons/fa";

import { ModalUserManager } from "./modal";

interface Props {
  search: FilterUser;
  data: UserModel[];
  onChangeSort: (search: FilterUser) => void;
  onUpdateData: (data: UserModel[]) => void;
}

const TableUserManager: React.FC<Props> = (props) => {
  const [selectUser, setSelecetUser] = useState<UserModel>();
  const [modalShowEdit, setModalShowEdit] = useState(false);
  const [modalShowActive, setModalShowActive] = useState(false);

  const _user = new UserRepository();

  function changeSort(value: string, prop: string) {
    const aux: any = {};
    aux[prop] = value;
    props.search.sort = aux;
    props.onChangeSort(props.search);
  }
  function changeActive() {
    if (selectUser) {
      _user.changeIsActive(selectUser.id, !selectUser.active).then((data) => {
        selectUser.active = !selectUser.active;
        props.onUpdateData(props.data.filter((x) => x.id != selectUser.id));
      });
      setModalShowActive(false);
    }
  }

  return (
    <>
      <Table bordered hover responsive borderless size="sm">
        <thead>
          <tr>
            <TableRow sort={props.search.sort.name} onSort={(ev: any) => changeSort(ev, "name")}>
              Nome Completo
            </TableRow>

            <TableRow sort={props.search.sort.email} onSort={(ev: any) => changeSort(ev, "email")}>
              E-mail
            </TableRow>

            <TableRow>Status</TableRow>

            <TableRow sort={props.search.sort.email} onSort={(ev: any) => changeSort(ev, "email")}>
              Tipe de Usuário
            </TableRow>

            <TableRow sort={props.search.sort.time_created} onSort={(ev: any) => changeSort(ev, "time_created")}>
              Cadastrado em
            </TableRow>
            <TableRow>Ação</TableRow>
          </tr>
        </thead>
        <tbody>
          {props.data.length === 0 && (
            <tr className="table-row-header text-center">
              <td colSpan={14}> Nenhum registro encontrado! </td>
            </tr>
          )}
          {props.data.map((_, index) => (
            <tr className="text-center" key={index}>
              {/* Full Name */}
              <td className="text-capitalize">{_.name}</td>
              {/* Email */}
              <td>{_.email}</td>
              {/* Active */}
              <td>{_.active ? "Ativo" : "Inativo"}</td>
              {/* Type */}
              <td>
                {_.user_type == 1
                  ? "Administrador"
                  : _.user_type == 2
                  ? "Interno"
                  : _.user_type == 3
                  ? "Externo (" +
                    (_.user_branch ? _.user_branch.split(",").length : 0) +
                    "," +
                    (_.user_department ? _.user_department.split(",").length : 0) +
                    "," +
                    (_.user_category ? _.user_category.split(",").length : 0) +
                    ")"
                  : "-"}
              </td>
              {/* Creation Date */}
              <td>{moment(_.time_creation).format(FormatType.MOMENT_FORMAT)}</td>
              {/* Action */}
              <td>
                {_.active && (
                  <Button
                    variant="outline-primary"
                    size="sm"
                    onClick={() => {
                      setSelecetUser(_);
                      setModalShowEdit(true);
                    }}
                  >
                    <FaEdit />
                  </Button>
                )}
                <Button
                  variant={_.active ? "outline-danger" : "outline-success"}
                  size="sm"
                  onClick={() => {
                    setSelecetUser(_);
                    setModalShowActive(true);
                  }}
                >
                  {_.active ? <FaTrash /> : <FaUndoAlt />}
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </Table>

      <div>
        <TablePagination
          onChangePage={(value) => {
            props.search.page = value;
            props.onChangeSort(props.search);
          }}
          currentPage={props.search.page}
          pageSize={props.search.pageSize}
          count={props.search.total}
        />
      </div>
      <ModalConfirm
        title={`Confirma se deseja ${selectUser?.active ? "remover" : "liberar"} o usuário ?`}
        subTitle={`Você deseja ${selectUser?.active ? "remover" : "liberar"} "${selectUser?.name}" do sistema?`}
        show={modalShowActive}
        onHide={() => {
          setSelecetUser(undefined);
          setModalShowActive(false);
        }}
        confirm={changeActive}
      />

      <ModalUserManager
        show={modalShowEdit}
        user={selectUser}
        onRefresh={() => {
          props.onChangeSort(props.search);
        }}
        onHide={() => {
          setModalShowEdit(false);
        }}
      />
    </>
  );
};

export default TableUserManager;
