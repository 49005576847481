import * as React from "react";
import { Button, Modal } from "react-bootstrap";
import "./index.scss";

interface Props {
    show: boolean;
    title?: string;
    subTitle?: string;
    onHide: () => void;
    confirm: (value?: boolean) => void;
}

export const ModalConfirm: React.FC<Props> = (props) => {

    return (
        <>
            <Modal
                centered
                aria-labelledby="contained-modal-title-vcenter"
                {...props}
            >
                <Modal.Header closeButton>
                    <Modal.Title id="contained-modal-title-vcenter" className="text-center" >
                        {props.title}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {props.subTitle}

                </Modal.Body>
                <Modal.Footer>
                    <Button variant="danger" onClick={() => props.onHide()}>
                        Não
                    </Button>
                    <Button variant="success" onClick={() => {
                        props.onHide();
                        props.confirm();
                    }}>
                        Sim
                    </Button>

                </Modal.Footer>
            </Modal>
        </>
    );
};
