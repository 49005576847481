import Switch from "react-switch";
import * as React from "react";
import { SyntheticEvent } from "react";
import "./index.scss";

type Props = {
  checked: boolean;
  disabled?: boolean;
  onChange: (
    checked: boolean,
    event: MouseEvent | SyntheticEvent<MouseEvent | KeyboardEvent, Event>,
    id: string
  ) => void;
};

const SwitchItem: React.FC<Props> = (props) => {

  return (
    <Switch

      checked={!!props.checked}
      onChange={props.onChange}
      onColor="#712905"
      disabled={props.disabled}
      // onHandleColor="#e41e25"
      handleDiameter={30}
      checkedIcon={false}
      uncheckedIcon={false}
      boxShadow="0px 1px 5px rgba(0, 0, 0, 0.6)"
      activeBoxShadow="0px 0px 1px 10px rgba(0, 0, 0, 0.2)"
      height={20}
      width={48}
      className="Switch"
      id="material-switch"
    />
  );
};

export default SwitchItem;
