// @flow
import * as React from "react";
import { Row, Col, Button, Spinner } from "react-bootstrap";
import { useToast } from "../../core/contexts/toast";
import { useState, useEffect } from "react";
import SelectSearch from "../../components/SelectSearch";
import { MessageType } from "../../core/config/message-type";
import { FilterHeader } from "../../components/FilterHeader";
import { BasePriceCatalogRepository, FilterBasePriceCatalog } from "../../core/repositories/base_price_catalog.repository";
import { ProductDepartmentModel } from "../../core/model/product_department";
import { ProductCategoryModel } from "../../core/model/product_category";
import { ProductModel } from "../../core/model/product";
import { ProductDepartmentRepository } from "../../core/repositories/product_department.repository";
import { ProductCategoryRepository } from "../../core/repositories/product_category.repository";
import { ProductRepository } from "../../core/repositories/product.repository";
import { BranchModel } from "../../core/model/branch";
import { ProductSubcategoryModel } from "../../core/model/product_subcategory";
import { PriceTableModel } from "../../core/model/price_table";
import { BranchRepository } from "../../core/repositories/branch.repository";
import { ProductPriceTableRepository } from "../../core/repositories/price_table.repository";
import { ProductSubcategoryRepository } from "../../core/repositories/product_subcategory.repository";
import { BaseModel } from "../../core/model/base-model";
import { MesorregionRepository } from "../../core/repositories/mesorregion.repository";
import { useAuth } from "../../core/contexts/auth";
import { BasePriceCatalogEditRepository } from "../../core/repositories/base_price_catalog_edit.repository";
import { basePriceCatalogContext } from "../../core/contexts/basePriceCatalogContext";

interface Props {
  filtro: FilterBasePriceCatalog;
  atualizarFiltro: (filtro: FilterBasePriceCatalog) => void;
  filtrarDados: (filtro: FilterBasePriceCatalog) => void;
}

export const Filter: React.FC<Props> = (props) => {
  const { toastShow } = useToast();

  const [branchList, setBranchList] = useState<BranchModel[]>([]);
  const [departmentList, setDepartmentList] = useState<ProductDepartmentModel[]>([]);
  const [categoryList, setCategoryList] = useState<ProductCategoryModel[]>([]);
  const [subcategoryList, setSubcategoryList] = useState<ProductSubcategoryModel[]>([]);
  const [productList, setProductList] = useState<ProductModel[]>([]);
  const [yearWeekList, setYearWeekList] = useState<BaseModel[]>([]);
  const [mesorregionList, setMesorregionList] = useState<BaseModel[]>([]);
  const [hasFetchedData, setHasFetchedData] = useState(false);
  const [carregarBtnExportar, setCarregarBtnExportar] = useState(false);

  const [loadingCategory, setLoadingCategory] = useState(false);
  const [loadingSubcategory, setLoadingSubcategory] = useState(false);
  const [loadingProduct, setLoadingProduct] = useState(false);
  const [loadingAll, setLoadingAll] = useState(false);

  const { carregando } = basePriceCatalogContext();

  const { loading } = useAuth();

  useEffect(() => {
    if (!loading) {
      if (!hasFetchedData) {
        setLoadingAll(true);

        const branchPromise = new BranchRepository().getFilter(props.filtro, "_post").then((res) => {
          setBranchList(res);
        });

        const departmentPromise = new ProductDepartmentRepository().getFilter(props.filtro, "_post").then((res) => {
          setDepartmentList(res);
        });

        const categoryPromise = getCategoryList(props.filtro);
        const subcategoryPromise = getSubcategoryList(props.filtro);

        const mesorregionPromise = new MesorregionRepository().getAll().then((res) => {
          setMesorregionList(res);
        });

        const productListPromise = getProductList(props.filtro);

        const yearWeekPromise = new BasePriceCatalogRepository().getYearWeekList().then((res) => {
          setYearWeekList(res);
          if (res.length > 0 && !props.filtro.year_week) {
            props.filtro.year_week = res[0].id.toString();
            props.filtrarDados(props.filtro);
          }
        });

        Promise.all([branchPromise, departmentPromise, categoryPromise, subcategoryPromise, mesorregionPromise, productListPromise, yearWeekPromise])
          .then(() => {
            setHasFetchedData(true);
          })
          .catch(() => {
            toastShow({
              title: "Erro!",
              subTitle: MessageType.ERROR_TRY,
              type: "error",
            });
          })
          .finally(() => {
            setLoadingAll(false);
          });
      }
    }
  }, [loading]);

  function getCategoryList(filter: FilterBasePriceCatalog) {
    new ProductCategoryRepository()
      .getFilter(filter, "_post")
      .then((res) => {
        setCategoryList(res);
      })
      .catch((error) => {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      })
      .finally(() => {
        setLoadingCategory(false);
      });
  }

  function getSubcategoryList(filter: FilterBasePriceCatalog) {
    new ProductSubcategoryRepository()
      .getFilter(filter, "_post")
      .then((res) => {
        setSubcategoryList(res);
      })
      .catch((error) => {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      })
      .finally(() => {
        setLoadingSubcategory(false);
      });
  }

  function getProductList(filter: FilterBasePriceCatalog) {
    new ProductRepository()
      .getFilter(filter, "_post")
      .then((res) => {
        setProductList(res);
      })
      .catch((error) => {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      })
      .finally(() => {
        setLoadingProduct(false);
      });
  }

  function apagarTudo() {
    new BasePriceCatalogEditRepository()
      .deleteAll()
      .then((req) => {
        toastShow({
          title: "Sucesso!",
          subTitle: "Dados apagados com sucesso!",
          type: "success",
        });
      })
      .catch(() => {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      });
  }

  function exportarDados() {
    setCarregarBtnExportar(true);
    new BasePriceCatalogRepository()
      .download(props.filtro)
      .then((req) => {
        var blob = new Blob([req.data], {
          type: req.headers["content-type"],
        });
        const link = document.createElement("a");
        link.href = window.URL.createObjectURL(blob);
        link.download = `preco_base_${new Date().toISOString()}.xlsx`;
        link.click();

        setCarregarBtnExportar(false);
      })
      .catch(() => {
        setCarregarBtnExportar(false);
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      });
  }
  return (
    <div className="stick">
      <FilterHeader
        title="Catálogo de Preço Base"
        loading={loadingAll}
        clearFilter={() => {
          var filtro = new FilterBasePriceCatalog();
          if (yearWeekList.length > 0) {
            filtro.year_week = yearWeekList[0].id.toString();
          }
          getCategoryList(filtro);
          getSubcategoryList(filtro);
          getProductList(filtro);
          props.atualizarFiltro(filtro);
        }}
        reload={() => {
          props.filtrarDados(props.filtro);
        }}
      >
        <>
          <Row>
            <Col>
              <SelectSearch
                name="Período Analisado"
                selectId="id"
                selectName="description"
                multiple={false}
                required={true}
                value={props.filtro.year_week}
                options={yearWeekList}
                onChange={(value) => {
                  props.filtro.year_week = value.toString();
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelectSearch
                name="Filial"
                selectId="id"
                selectName="description"
                multiple={true}
                value={props.filtro.branch_code}
                options={branchList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.branch_code = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelectSearch
                name="Departamento"
                selectId="id"
                selectName="description"
                multiple={true}
                value={props.filtro.department_code}
                options={departmentList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.department_code = value;
                  props.filtro.category_code = "";
                  props.filtro.subcategory_code = "";
                  props.filtro.product_code = "";
                  props.atualizarFiltro(props.filtro);

                  setLoadingCategory(true);
                  setLoadingSubcategory(true);
                  setLoadingProduct(true);

                  getCategoryList(props.filtro);
                  getSubcategoryList(props.filtro);
                  getProductList(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelectSearch
                name="Categoria"
                selectId="id"
                selectName="description"
                disabled={loadingCategory}
                multiple={true}
                value={props.filtro.category_code}
                options={categoryList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.category_code = value;
                  props.filtro.subcategory_code = "";
                  props.filtro.product_code = "";
                  props.atualizarFiltro(props.filtro);

                  setLoadingSubcategory(true);
                  setLoadingProduct(true);

                  getSubcategoryList(props.filtro);
                  getProductList(props.filtro);
                }}
              />
            </Col>
            <Col></Col>
          </Row>
          <Row>
            <Col>
              <SelectSearch
                name="Subcategoria"
                selectId="id"
                selectName="description"
                disabled={loadingSubcategory}
                multiple={true}
                value={props.filtro.subcategory_code}
                options={subcategoryList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.subcategory_code = value;
                  props.filtro.product_code = "";
                  props.atualizarFiltro(props.filtro);

                  setLoadingProduct(true);

                  getProductList(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelectSearch
                name="Mesorregião"
                selectId="id"
                selectName="description"
                multiple={true}
                value={props.filtro.mesorregion_code}
                options={mesorregionList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.mesorregion_code = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>

            <Col>
              <SelectSearch
                name="Produto"
                selectId="id"
                selectName="description_show"
                disabled={loadingProduct}
                multiple={true}
                value={props.filtro.product_code}
                options={productList}
                onChange={(value) => {
                  value = value.toString();
                  props.filtro.product_code = value;
                  props.atualizarFiltro(props.filtro);
                }}
              />
            </Col>

            <Col></Col>

            <Col>
              <div className="d-flex justify-content-end margin-top-32">
                {process.env.REACT_APP_BASE_BACK && (
                  <div>
                    <Button className="ms-1 text-white" variant="danger" onClick={apagarTudo}>
                      {carregarBtnExportar && <Spinner size="sm" />}
                      Apagar Dados
                    </Button>
                  </div>
                )}
                <div>
                  <Button className="ms-1 text-white" variant="secondary" onClick={exportarDados} disabled={carregarBtnExportar || !props.filtro.year_week}>
                    {carregarBtnExportar && <Spinner size="sm" />}
                    Relatório
                  </Button>
                </div>
                <Button
                  className="ms-1"
                  variant="primary"
                  disabled={!props.filtro.year_week || carregando}
                  onClick={() => {
                    props.filtrarDados(props.filtro);
                  }}
                >
                  Buscar
                </Button>
              </div>
            </Col>
          </Row>
        </>
      </FilterHeader>
    </div>
  );
};
