import * as React from "react";
import { Container } from "react-bootstrap";
import BasePriceCatalogTable from "./table";
import { useEffect, useState } from "react";
import { FilterBasePriceCatalog } from "../../core/repositories/base_price_catalog.repository";
import { Filter } from "./filter";
import { basePriceCatalogContext } from "../../core/contexts/basePriceCatalogContext";
import { useToast } from "../../core/contexts/toast";
import { useAuth } from "../../core/contexts/auth";
import "./index.scss";
import api from "../../core/services/api";
import { useSearchParams } from "react-router-dom";

var count: number = 0;

const BasePrice: React.FC = () => {
  const { toastShow } = useToast();
  const { user } = useAuth();

  const { filtroNoContexto, carregarLista, atualizarInterface, atualizarFiltroContexto, setCarregando, removerLista, adicionarLista } =
    basePriceCatalogContext();

  const [search, setSearch] = useState(new FilterBasePriceCatalog());
  const [loadingFirstTime, setLoadingFirstTime] = useState(true);
  const hasFetchedData = React.useRef(false); // Usando useRef para armazenar o estado de carregamento
  const [searchParams, setSearchParams] = useSearchParams();

  // const setCookie = (name: any, value: any, days: any) => {
  //   const expires = new Date(Date.now() + days * 86400000).toUTCString();
  //   document.cookie = `${name}=${value}; expires=${expires}; path=/`;
  //   var c = new Cookies();
  //   c.set("my_cookie", "valorDoCookie", { path: "/", expires: new Date(Date.now() + days * 86400000) });
  // };

  useEffect(() => {
    if (hasFetchedData.current) {
      return;
    } // Verifica se os dados já foram buscados
    setCarregando(true);
    hasFetchedData.current = true; // Atualiza o estado de carregamento

    searchParams.forEach((value, key) => {
      if (value != "undefined") {
        (search as any)[key] = value;
      }
    });

    searchData(search);

    getData(search).then(async () => {
      const num_nivel = searchParams.get("num_nivel");

      if (num_nivel) {
        for (let index = 0; index < Number.parseInt(num_nivel); index++) {
          await adicionarLista(search, index);
        }
      }
    });
    // Chame essa função no seu código React para definir o cookie
    // setCookie("my_cookie", "valorDoCookie", 7); // Define um cookie que expira em 7 dias
  }, []);

  async function getData(search: FilterBasePriceCatalog) {
    // await api.get("/test");
    // var a = await api.get("base_price_catalog_post");
    // api.post("post_me1").then((res) => { });
    // api.get("get_me1").then((res) => { });

    // api.get("login-cookie").then((res) => {
    //   console.log(res.data);
    //   if(res.data && res.data.length > 0)
    //     alert("Cookie setado com sucesso!");
    // }  );

    // api.get("get-cookie").then((res) => {
    //   console.log(res.data);
    //   if(res.data && res.data.length > 0)
    //     alert("Cookie setado com sucesso!");
    // }  );
    try {
      const _count = count++;

      if (_count === count - 1) {
        await carregarLista(search);
        atualizarInterface();
      }
    } catch (error) {
      toastShow({
        title: "Erro!",
        subTitle: "Aconteceu algo inesperado, por favor tente novamente mais tarde!",
        type: "error",
      });
    }
  }

  function atualizarFiltro(newSearch: FilterBasePriceCatalog) {
    var a = {} as any;
    delete a.cod_user;
    Object.keys(newSearch).forEach((key) => {
      if ((newSearch as any)[key]) {
        a[key] = (newSearch as any)[key];
      }
    });
    setSearchParams(a);
    setSearch({ ...newSearch });
  }

  async function searchData(newSearch: FilterBasePriceCatalog) {
    // limparLista();
    setCarregando(true);

    newSearch.cod_user = user?.id;
    newSearch.flg_aberto = false;
    setSearch({ ...newSearch });

    atualizarFiltroContexto(newSearch);

    getData(newSearch);
  }

  return (
    <div className="BasePrice">
      <Container className="first max-98">
        <Filter filtro={search} atualizarFiltro={atualizarFiltro} filtrarDados={searchData} />
        <div>
          <BasePriceCatalogTable
            search={filtroNoContexto}
            open={search.flg_aberto}
            loadingFirstTime={loadingFirstTime}
            setLoadingFirstTime={(value: boolean) => {
              setLoadingFirstTime(value);
            }}
          />
        </div>
      </Container>
    </div>
  );
};

export default BasePrice;
