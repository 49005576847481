import * as React from 'react';
import { useEffect, useState } from 'react';
import { Container, Card, Button, Spinner } from 'react-bootstrap';
import { useToast } from '../../core/contexts/toast';
import { Filter } from './filter';
import { FaDownload } from 'react-icons/fa';
import loadingGif from '../../assets/imagens/loading.gif';
import { MessageType } from '../../core/config/message-type';
import { LogApiModel } from '../../core/model/log_api';
import { FilterLogApi, LogApiRepository } from '../../core/repositories/log_api.repository';
import { TableLogApi } from './table';
import './index.scss';

var count: number = 0;

export const LogApi: React.FC = () => {
	const [loading, setLoading] = useState(true);
	const { toastShow } = useToast();
	const [search, setSearch] = useState(new FilterLogApi());
	const [loadingBtnDownload, setLoadingBtnDownload] = useState(false);
	const [data, setData] = useState<LogApiModel[]>([]);

	const _logApiRepository = new LogApiRepository();

	useEffect(() => {
		getList(search);
	}, []);

	function getList(search: FilterLogApi) {
		search.page = 1;

		setSearch({ ...search });
		loadingList(search);
	}
	function changeSort(search: FilterLogApi) {
		setSearch({ ...search });
		loadingList(search);
	}

	function loadingList(search: FilterLogApi) {
		const _count = count++;
		setLoading(true);

		_logApiRepository
			.getFilter(search)
			.then((data) => {
				if (_count === count - 1) {
					search.total = data.length ? data[0].total_records : data.length;
					setSearch({ ...search });

					setData(data);
					setLoading(false);
				}
			}).catch(() => {
				setLoading(false);
				toastShow({
					title: 'Erro!',
					subTitle: MessageType.ERROR_TRY,
					type: 'error',
				});
			});
	}

	function download() {
		setLoadingBtnDownload(true);
		_logApiRepository.download(search).then((req) => {
			var blob = new Blob([req.data], {
				type: req.headers['content-type'],
			});
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = `log_api_${new Date().toISOString()}.xlsx`;
			link.click();
			setLoadingBtnDownload(false);
		}).catch(() => {
			setLoadingBtnDownload(false);
			toastShow({
				title: 'Erro!',
				subTitle: MessageType.ERROR_TRY,
				type: 'error',
			});
		});
	}

	return (
		<Container className="LogApi max-98">
			<Filter search={search} onChangeFilter={getList} />
			{/* <Button className="download-bnt" disabled={loadingBtnDownload} onClick={download} variant="secondary">
				{loadingBtnDownload ? <Spinner size="sm" animation="border" role="status" /> : <FaDownload />}
			</Button> */}
			<Card className="card-d">
				<Card.Body>
					{loading ?
						<div className="height-500">
							<div className="text-center loading align-vertical  ">
								<img className="width-150 height-150" src={loadingGif} alt="" />
							</div>
						</div>
						:
						<TableLogApi search={search} onChangeSort={changeSort} data={data} />
					}
				</Card.Body>
			</Card>
		</Container>
	);
};
