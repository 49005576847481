import * as React from "react";
import { OverlayTrigger, Spinner, Table, Tooltip } from "react-bootstrap";
import { FaInfoCircle } from "react-icons/fa";
import Skeleton from "react-loading-skeleton";
import TableRow from "../../components/TableRow";
import TablePagination from "../../components/TablePagination";
import { BasePriceCatalogModel, BasePriceCatalogModelGranularidade } from "../../core/model/base_price_catalog";
import { BasePriceCatalogRepository, FilterBasePriceCatalog } from "../../core/repositories/base_price_catalog.repository";
import "react-loading-skeleton/dist/skeleton.css";
import { TableTr } from "./tableTr";
import { basePriceCatalogContext } from "../../core/contexts/basePriceCatalogContext";
import { atualizaTamanhoTabela } from "../../core/utils/functions";
import moment from "moment";
import { FormatType } from "../../core/config/format-type";
import { useToast } from "../../core/contexts/toast";
import { MessageType } from "../../core/config/message-type";
import { ModalBuyers } from "./modalBuyers";
import { ModalEdits } from "./modalEdits";

interface Props {
  search: FilterBasePriceCatalog;
  open: boolean;
  loadingFirstTime: boolean;
  setLoadingFirstTime(valor: boolean): void;
}

export function updateHeightTable() {
  setTimeout(() => {
    atualizaTamanhoTabela();
  }, 200);
}

const BasePriceCatalogTable: React.FC<Props> = (props) => {
  const { lista, carregando } = basePriceCatalogContext();
  const [carregandoPrimeiraVez, setCarregandoPrimeiraVez] = React.useState(true);
  const [lastTimeModel, setLastTimeModel] = React.useState<string>();
  const [modalBuyers, setModalBuyers] = React.useState<BasePriceCatalogModel>();
  const [modalEditados, setModalEditados] = React.useState<BasePriceCatalogModel>();

  const { toastShow } = useToast();

  function title(item: any) {
    return BasePriceCatalogModelGranularidade.INTERFACE[item.num_level] + ": " + item[BasePriceCatalogModelGranularidade.DESCRICAO[item.num_level]]?.toString();
  }

  React.useEffect(() => {
    updateHeightTable();
  }, [props.open]);

  React.useEffect(() => {
    if (carregando && carregandoPrimeiraVez) {
      setCarregandoPrimeiraVez(false);
    }
  }, [carregando]);

  React.useEffect(() => {
    new BasePriceCatalogRepository()
      .getModelLastTime(props.search.year_week)
      .then((res) => {
        if (res) {
          setLastTimeModel(res);
        }
      })
      .catch(() => {
        toastShow({
          title: "Erro!",
          subTitle: MessageType.ERROR_TRY,
          type: "error",
        });
      });
  }, [props.search.year_week]);

  return (
    <div id="tabela">
      <Table className="rounded " hover bordered responsive size="sm">
        <thead>
          <tr className="table-header">
            <TableRow classNameTh="width-200 granularidade">Granularidade</TableRow>
            <TableRow classNameTh="width-70">Estoque Disponível</TableRow>
            <TableRow classNameTh="width-70">Compra em Aberto</TableRow>

            <TableRow classNameTh="width-90">Tendência de Demanda</TableRow>
            <TableRow classNameTh="width-90">Tendência de Preço</TableRow>
            {/* <TableRow classNameTh="width-70">Margem <br /> Tendência de Preço</TableRow> */}

            <TableRow classNameTh="width-70">Volume Vendido</TableRow>
            <TableRow classNameTh="width-70">Preço PMZ</TableRow>

            <TableRow classNameTh="width-84">Preço do Concorrente</TableRow>
            <TableRow classNameTh="width-70">Preço Tabela Atual</TableRow>
            <TableRow classNameTh="width-70">
              {/* Δ Preço <br /> Tabela X PMZ */}
              Margem <br /> Preço Tabela
            </TableRow>
            <TableRow classNameTh="width-70">Demanda IA (S+1)</TableRow>
            <TableRow classNameTh="width-70">
              Preço Sugerido
              <br /> IA (S+1)
            </TableRow>
            <TableRow classNameTh="width-70">
              {/* Δ Preço <br /> IA X PMZ */}
              Margem <br /> Preço IA
            </TableRow>

            <TableRow classNameTh="width-90">Automatizar?</TableRow>
            <TableRow classNameTh="width-60">Aceitar?</TableRow>
            <TableRow classNameTh="width-120">Editar Preço</TableRow>
            <TableRow classNameTh="width-70">
              {/* Δ Preço Editado X PMZ */}
              Margem <br /> Preço Editado
            </TableRow>
            <TableRow classNameTh="width-60">Δ Preço Editado X IA</TableRow>
            <TableRow classNameTh="width-120">Editar Preço Mínimo</TableRow>
            <TableRow classNameTh="width-70">
              {/* Δ Preço Mínimo X PMZ */}
              Margem <br /> Preço Mínimo
            </TableRow>
          </tr>
        </thead>
        {/* <tbody className="margin-top-60"> */}

        <tbody>
          {carregando ? (
            Array.from({ length: 9 }).map((_, index) => (
              <tr className="height-42" key={"skeleton-tr" + index}>
                {Array.from({ length: 20 }).map((_, index) => (
                  <td key={"skeleton-td" + index}>
                    <Skeleton />
                  </td>
                ))}
              </tr>
            ))
          ) : lista.length == 0 ? (
            <tr>
              <td colSpan={23} className="text-center">
                {carregandoPrimeiraVez ? "Clique em buscar para carregar os dados!" : "Nenhum registro encontrado!"}
              </td>
            </tr>
          ) : (
            lista.map(
              (item, index) =>
                !item.oculta && (
                  <tr
                    key={"tr-" + item.id + index}
                    className={
                      "font-size-" +
                      (14 - item.num_level * 0) +
                      (item.is_all_replicated ? " nivel-yellow-" + (item.num_level + 1) : " nivel-" + (item.num_level + 1))
                    }
                    title={title(item)}
                  >
                    <TableTr
                      data={item}
                      index={index}
                      search={props.search}
                      onModalBuyers={() => {
                        setModalBuyers(item);
                      }}
                      onModalEdit={() => {
                        setModalEditados(item);
                      }}
                    />
                  </tr>
                )
            )
          )}
          <tr className=" border-0">
            <td colSpan={23} className={"text-start border-0 bs-white "}>
              {lastTimeModel === undefined ? (
                <Spinner size="sm" />
              ) : (
                <>
                  Última atualização do banco de dados:
                  <b> {moment.utc(lastTimeModel).format(FormatType.MOMENT_FORMAT)}</b>
                </>
              )}
            </td>
          </tr>
        </tbody>
      </Table>
      <ModalBuyers
        show={!!modalBuyers}
        data={modalBuyers}
        search={props.search}
        onHide={() => {
          setModalBuyers(undefined);
        }}
      />
      <ModalEdits
        show={!!modalEditados}
        data={modalEditados}
        search={props.search}
        onHide={() => {
          setModalEditados(undefined);
        }}
      />
    </div>
  );
};

export default BasePriceCatalogTable;
