import * as React from 'react';
import { Annotations, Data, Layout } from 'plotly.js';
import { DemandForecastModel } from '../../core/model/demand_forecast';
import { FilterDemandForecast } from '../../core/repositories/demand_forecast.repository';
import moment from 'moment';
import { CollecionName } from '../../core/config/collection-name';
import { colors_plotly } from '../../core/utils/functions';

interface Props {
	search: FilterDemandForecast;
	data: DemandForecastModel[];
	onChangeSort: (search: FilterDemandForecast) => void;
	// onUpdateData: (data: DemandForecastModel) => void;
}
// declare var Plot: any;
declare var Plotly: any;

const ChartDemandForecast: React.FC<Props> = (props) => {

	var colors = colors_plotly();
	var xValues = props.data.map((element) => {
		if (props.search && props.search.scale == "m")
			return moment(element.label).format("MMM, YYYY");
		return moment(element.label).format(CollecionName.MOMENT_FORMAT)
	})
	var width = 3.5
	var hovertemplate = props.search.scale == "m" ? "%{yaxis.title.text}: %{y:.2f} <br>%{xaxis.title.text}: %{x} <br>" : "%{yaxis.title.text}: %{y:.2f} <br>%{xaxis.title.text}: %{x} <br>"
	var data = [
		{
			type: 'scatter',
			name: "Valor Real",
			x: xValues,
			y: props.data.map((element) => element.real_value),
			hovertemplate: hovertemplate,
			line: {
				color: colors[0],
				dash: 'solid',
				width: width,
			},
		},
		{
			type: 'scatter',
			name: "Previsão de Vendas",

			x: xValues,
			y: props.data.map((element) => element.forecast_value),
			// hovertemplate:
			// 	`%{yaxis.title.text}: %{y:R$,.0f} <br>%{xaxis.title.text}: %{x} <br><extra></extra>`
			// ,
			hovertemplate: hovertemplate,

			line: {
				color: colors[1],
				dash: 'dashdot',
				width: width,
			},
		},
		{
			type: 'scatter',
			name: "Valor Real A-1",
			hovertemplate: hovertemplate,
			x: xValues,
			y: props.data.map((element) => element.real_value_py),

			line: {
				color: colors[2],
				width: width,
			}
		}
	] as Data[];


	var layout = {
		separators: ',.',
		margin: {
			t: 5,
			b: 15,
		},
		xaxis: {
			title: props.search?.scale == "m" ? "Mês" : props.search?.scale == "s" ? "Semana" : "Dia",
			titlefont: {
				size: 1,
				color: 'red'
			}
			// visible: false,

		},
		yaxis: {
			title: props.search?.value == "Revenue" ? "Receita" : "Quantidade",
			tickformat: props.search?.value == "Revenue" ? "R$" : ""
		},
		showlegend: true,
		legend: {
			orientation: "h",
			xanchor: "center",
			yanchor: "top",
			y: -0.3, // play with it
			x: 0.5   // play with it
		},

		hoverlabel: {
			bgcolor: 'white',
			font: {
				color: 'black'
			}
		}
	} as Layout;

	var id = Math.random().toString(36).substr(2, 9);

	React.useEffect(() => {
		// for (var i = 0; i < yValues.length; i++) {
		// 	for (var j = 0; j < xValues.length; j++) {
		// 		var result = {
		// 			x: xValues[j].toString(),
		// 			y: yValues[i].toString(),
		// 			// text: Number.parseFloat(zValues[i][j].toString()).toFixed(2),
		// 			showarrow: false,
		// 			font: {
		// 				color: 'white'
		// 			},
		// 		} as Partial<Annotations>;
		// 		layout.annotations.push(result);
		// 	}
		// }
		Plotly.newPlot('myDiv' + id, data, layout, { showSendToCloud: true });

	}, []);

	return (
		<>
			{props.data && (
				<div className="position-absolute right-12 ">
					Última atualização do modelo: <b>{moment(props.data[0].date_creation).format('DD/MM/YYYY')}</b>
				</div>
			)}
			<div id={"myDiv" + id} className='myDiv mt-4' />
		</>
	);
};

export default ChartDemandForecast;
