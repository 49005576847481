import * as React from 'react';
import { Row, Col, Form, Button } from 'react-bootstrap';
import { useToast } from '../../core/contexts/toast';
import { useState, useEffect, FC } from 'react';
import { MessageType } from '../../core/config/message-type';
import { convertArrayToCsvText } from '../../core/utils/functions';
import { FilterHeader } from '../../components/FilterHeader';
import SelectSearch from '../../components/SelectSearch';
import { CustomerClusterModel } from '../../core/model/customer_cluster';
import { FilterChartCohort } from '../../core/repositories/chart_cohort.repository';
import { CustomerClusterRepository } from '../../core/repositories/customer_cluster.repository';
import SwitchItem from '../../components/Switch';
import { IoMdGitCompare } from 'react-icons/io';

interface Props {
	search: FilterChartCohort;
	onChangeFilter: (search: FilterChartCohort) => void;
	onChangeCompare: () => void;
}

export const Filter: React.FC<Props> = (props) => {
	const { toastShow } = useToast();
	const [customerClusterList, setCustomerClusterList] = useState<CustomerClusterModel[]>([]);

	useEffect(() => {
		try {
			new CustomerClusterRepository().getAll().then((res) => {
				setCustomerClusterList(res);
			});
		} catch (error) {
			toastShow({
				title: 'Erro!',
				subTitle: MessageType.ERROR_TRY,
				type: 'error',
			});
		}
	}, []);


	function handleClearFilter() {
		props.onChangeFilter(new FilterChartCohort());
	}

	return (
		<div className="stick">

			<FilterHeader title="Cohort Analysis" clearFilter={handleClearFilter} reload={() => { props.onChangeFilter(props.search) }}>
				<Row >
					<Col md={2}>
						<SelectSearch
							name="Cluster"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.cluster}
							options={customerClusterList}
							onChange={(value) => {
								if (Array.isArray(value)) props.search.cluster = convertArrayToCsvText(value);
								else props.search.cluster = value.toString();
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col md={2}>
						<SelectSearch
							name="Tipo"
							selectId="id"
							selectName="description"
							multiple={false}
							required={true}
							value={props.search.type}
							options={
								[{
									id: 'retention_client',
									description: 'Retenção de Clientes'
								},
								{
									id: 'average_ticket',
									description: 'Ticket Médio'
								},
								{
									id: 'total_sales',
									description: 'Total de Vendas'
								},
								]}
							onChange={(value) => {
								props.search.type = value.toString();
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col md={2}>
						<Form.Group className="mb-3 mr-3 SelectSearch">
							<Form.Label className="font-size-14">Valor Absoluto</Form.Label>
							<br />
							<SwitchItem
								checked={props.search.absolute}
								onChange={() => {
									props.search.absolute = !props.search.absolute;
									props.onChangeFilter(props.search);
								}}
							></SwitchItem>
						</Form.Group>
					</Col>

					<Col md={6} className='align-vertical justify-content-end'>

						<Button variant="outline-primary" onClick={() => {
							props.onChangeCompare();
						}}>
							<IoMdGitCompare />
						</Button>

					</Col>



					{/* <Col className="font-size-12" md={3}>
						<SelectSearch
							name="Cliente"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.customer_code}
							options={customerList}
							disabled={props.search.hide_customer}
							onChange={(value) => {
								if (Array.isArray(value)) props.search.customer_code = convertArrayToCsvText(value);
								else props.search.customer_code = value.toString();
								props.search.customer_code_name = getNameFilter(customerList,value );
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col className="font-size-12" md={3}>
						<SelectSearch
							name="Cidade"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.city}
							options={customerCityList}
							disabled={props.search.hide_customer}
							onChange={(value) => {
								if (Array.isArray(value)) props.search.city = convertArrayToCsvText(value);
								else props.search.city = value;
								props.onChangeFilter(props.search);
							}}
						/>
					</Col> */}

					{/* <Col className="font-size-12" md={3}>
						<SelectSearch
							name="Categoria"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.category_id}
							options={productCategoryList}
							onChange={(value) => {
								props.search.category_id = value.toString();
								props.search.category_name = getNameFilter(productCategoryList, value);
								props.onChangeFilter(props.search);
							}}
						/>
					</Col> */}

					{/* <Col className="font-size-12" md={1}>
						<Form.Group className="mb-3 mr-3 SelectSearch">
							<Form.Label className="font-size-14">Visão por Cluster</Form.Label>
							<br />
							<Switch
								checked={props.search.hide_customer}
								onChange={() => {
									props.search.hide_customer = !props.search.hide_customer;
									props.onChangeFilter(props.search);
								}}
							></Switch>
						</Form.Group>
					</Col> */}
				</Row>
			</FilterHeader>

		</div>
	);
};
