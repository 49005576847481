import { CollecionName } from '../config/collection-name';
import { ActiveClusterModel } from '../model/active_cluster';
import { BasicFilter } from '../model/base-model';
import { Repository } from './repository';

export class FilterActiveCluster extends BasicFilter<ActiveClusterModel> {

	cluster = '';
	customer_code = '';
	customer_code_name = '';
	city: string = '';
	category_id = '';
	category_name = '';
	hide_customer: boolean = false;


}

export class ActiveClusterRepository extends Repository<ActiveClusterModel> {
	constructor() {
		super(CollecionName.ACTIVE_CLUSTER);
	}

}
