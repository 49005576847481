import { MessageType } from "../../core/config/message-type";
import { Form, Button, Spinner } from "react-bootstrap";
import logo from "../../assets/imagens/logo.png";
import React, { useEffect, useState } from "react";
import { useAuth } from "../../core/contexts/auth";
import "./index.scss";
import Cookies from "universal-cookie/es6";

export const Login: React.FC = () => {
  const { checkEmail, signOut } = useAuth(); // myauth
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(``);

  useEffect(() => {
    signOut(false);
  }, []);

  async function handleSubmit(event: any) {
    setValidated(true);
    const form = event.currentTarget;
    event.preventDefault();
    event.stopPropagation();
    setLoading(true);

    try {
      // await login(form[0].value, '123');
      await checkEmail(form[0].value);
      if (process.env.REACT_APP_BASE_BACK) {
        new Cookies().set(
          "@RNauth:user",
          { name: "DEV_USER", email: form[0].value },
          {
            path: "/",
            httpOnly: false,
          }
        );
        window.location.href = `${window.location.origin}/loginsuccess`;
      } else {
        window.location.href = `/.auth/login/aad?post_login_redirect_uri=${window.location.origin}/loginsuccess`;
      }
    } catch (error) {
      setLoading(false);
      if (error && typeof error == "string") {
        setError(error);
      } else {
        setError(MessageType.ERROR_TRY);
      }
    }
  }
  return (
    <section className="login">
      <div className="inner">
        <div className="text-center">
          <img className="logo" src={logo} alt="Logo" />
        </div>
        {/* <label className="mt-3 text-center gray" color="gray">
					Sign in to continue to A2Go
				</label> */}
        <Form validated={validated} onSubmit={handleSubmit}>
          <Form.Group controlId="formBasicEmail">
            <Form.Control className="mt-4" required type="email" placeholder="Enter e-mail" onChange={() => setError("")} />
            <Form.Control.Feedback type="invalid">Required!</Form.Control.Feedback>
          </Form.Group>

          {error && (
            <div className="error text-danger mb-2 font-size-12">
              <label> {error}</label>
            </div>
          )}

          <div className="d-grid gap-2 mt-3">
            <Button type="submit" className="mt-4" disabled={loading}>
              {loading ? (
                <Spinner size="sm" animation="border" role="status"></Spinner>
              ) : (
                <>Entrar</>
                // <b className="text-white"></b>
              )}
            </Button>
          </div>
        </Form>
      </div>
      {/* <footer>
                    <div className="text-center">
                        <p>
                            <Alert.Link  className="text-primary" href="https://analytics2go.com">
                                <FaRegCopyright />
                                2022 Analytics2Go
                            </Alert.Link>
                        </p>
                    </div>
                </footer> */}
    </section>
  );
};
