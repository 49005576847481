import { CollecionName } from "../config/collection-name";
import { BasicFilter } from "../model/base-model";
import { UserModel } from "../model/user";
import { Repository } from "./repository";

export class FilterUser extends BasicFilter<UserModel> {
  active: boolean = true;
  name = "";
}

export class UserRepository extends Repository<UserModel> {
  constructor() {
    super(CollecionName.USER);
  }

  public async changeIsActive(id: number, status: boolean) {
    const res = await this.api.post(`${this.collectionName}_change_active_post`, {
      id,
      active: status,
    });
    return res.data;
  }
}
