import React from "react";

import AuthRoutes from "./auth.routes";
import AppRoutes from "../routes/app.routes";
import { useAuth } from "../core/contexts/auth";

const Routes: React.FC = () => {
  // O app vai voltar para as rotas de autenticação
  const { signed, getUserCookie } = useAuth();
  return signed || getUserCookie() ? <AppRoutes /> : <AuthRoutes />;
  // return  <AppRoutes />
};

export default Routes;
