// @flow
import * as React from 'react';
import { Card, Row, Col, Button, Collapse, Form, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { useToast } from '../../core/contexts/toast';
import { useState, useEffect, FC } from 'react';
import SelectSearch from '../../components/SelectSearch';
import { MessageType } from '../../core/config/message-type';
import 'react-datepicker/dist/react-datepicker.css';
import { FilterCustomerSegmentation } from '../../core/repositories/customer_segmentation.repository';
import { CustomerSegmentationModel } from '../../core/model/customer_segmentation';
import { CustomerClusterModel } from '../../core/model/customer_cluster';
import { CustomerCityModel } from '../../core/model/customer_city';
import { CustomerModel } from '../../core/model/customer';
import { ProductCategoryModel } from '../../core/model/product_category';
import { CustomerClusterRepository } from '../../core/repositories/customer_cluster.repository';
import { CustomerCityRepository } from '../../core/repositories/customer_city.repository';
import { CustomerRepository } from '../../core/repositories/customer.repository';
import { CustomerSegmentationRepository } from '../../core/repositories/customer_segmentation.repository';
import { ProductCategoryRepository } from '../../core/repositories/product_category.repository';
import { convertArrayToCsvText, getNameFilter } from '../../core/utils/functions';
import Switch from '../../components/Switch';
import { FilterHeader } from '../../components/FilterHeader';

interface Props {
	search: FilterCustomerSegmentation;

	onChangeFilter: (search: FilterCustomerSegmentation) => void;
}

export const Filter: React.FC<Props> = (props) => {
	const { toastShow } = useToast();

	const [customerClusterList, setCustomerClusterList] = useState<CustomerClusterModel[]>([]);
	const [customerList, setCustomerList] = useState<CustomerModel[]>([]);
	const [customerCityList, setCustomerCityList] = useState<CustomerCityModel[]>([]);
	const [productCategoryList, setProductCategoryList] = useState<ProductCategoryModel[]>([]);

	useEffect(() => {
		try {
			new CustomerClusterRepository().getAll().then((res) => {
				setCustomerClusterList(res);
			});
			new CustomerCityRepository().getAll().then((res) => {
				setCustomerCityList(res);
			});
			new CustomerRepository().getAll().then((res) => {
				setCustomerList(res);
			});
			new ProductCategoryRepository().getAll().then((res) => {
				setProductCategoryList(res);
			});
		} catch (error) {
			toastShow({
				title: 'Erro!',
				subTitle: MessageType.ERROR_TRY,
				type: 'error',
			});
		}
	}, []);


	function handleClearFilter() {
		var selects = document.getElementsByTagName('select');
		for (var i = 0; i < selects.length; i++) {
			selects[i].value = '';
		}
		props.onChangeFilter(new FilterCustomerSegmentation());
	}

	return (
		<div className="stick">

			<FilterHeader title="Customer Segmentation" clearFilter={handleClearFilter} reload={() => { props.onChangeFilter(props.search) }}>
				<Row >
					<Col className="font-size-12" md={2}>
						<SelectSearch
							name="Cluster"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.cluster}
							options={customerClusterList}
							onChange={(value) => {
								if (Array.isArray(value)) props.search.cluster = convertArrayToCsvText(value);
								else props.search.cluster = value.toString();
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col className="font-size-12" md={3}>
						<SelectSearch
							name="Cliente"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.customer_code}
							options={customerList}
							disabled={props.search.hide_customer}
							onChange={(value) => {
								if (Array.isArray(value)) props.search.customer_code = convertArrayToCsvText(value);
								else props.search.customer_code = value.toString();
								props.search.customer_code_name = getNameFilter(customerList, value);
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col className="font-size-12" md={3}>
						<SelectSearch
							name="Cidade"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.city}
							options={customerCityList}
							disabled={props.search.hide_customer}
							onChange={(value) => {
								if (Array.isArray(value)) props.search.city = convertArrayToCsvText(value);
								else props.search.city = value;
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col className="font-size-12" md={3}>
						<SelectSearch
							name="Categoria"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.category_id}
							options={productCategoryList}
							onChange={(value) => {
								props.search.category_id = value.toString();
								props.search.category_name = getNameFilter(productCategoryList, value);
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col className="font-size-12" md={1}>
						<Form.Group className="mb-3 mr-3 SelectSearch">
							<Form.Label className="font-size-14">Visão por Cluster</Form.Label>
							<br />
							<Switch
								checked={props.search.hide_customer}
								onChange={() => {
									props.search.hide_customer = !props.search.hide_customer;
									props.onChangeFilter(props.search);
								}}
							></Switch>
						</Form.Group>
					</Col>
				</Row>
			</FilterHeader>

		</div>
	);
};
