import { CollecionName } from "../config/collection-name";
import { ProductDepartmentModel } from "../model/product_department";
import { Repository } from "./repository";

export class FilterProductDepartment {

  id = "";
  description = "";

  sort: Partial<ProductDepartmentModel> = {
    // distribution_center: 'asc',
  };

  page = 1;
  pageSize = 20;
  total = 0;
}

export class ProductDepartmentRepository extends Repository<ProductDepartmentModel> {
  constructor() {
    super(CollecionName.PRODUCT_DEPARTMENT);
  }
}
