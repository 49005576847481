import React from "react";

import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useAuth } from "../core/contexts/auth";
import { UserType } from "../core/config/user-type";

const RequireAuth = ({ allowedRoles }: { allowedRoles: any }) => {
  const { getUserCookie } = useAuth();
  const location = useLocation();

  return allowedRoles.includes(getUserCookie()?.email) ? (
    <Outlet />
  ) : (
    <Navigate to="/page-404" state={{ from: location }} replace />
  );
};

export default RequireAuth;

export function check_type(type: UserType) {
  const { getUserCookie } = useAuth();
  const user_type = getUserCookie()?.user_type || 1000
  return user_type <= type
}
