import * as React from 'react';
import { Data, Layout } from 'plotly.js';
import { ActiveClusterModel } from '../../core/model/active_cluster';
import { FilterActiveCluster } from '../../core/repositories/active_cluster.repository';
import { colors_plotly } from '../../core/utils/functions';

// import createPlotlyComponent from "react-plotly.js/factory";

// import * as Graphic from 'plotly.js-dist-min';
// import Plotly from 'plotly.js-dist-min';

interface Props {
	search: FilterActiveCluster;
	data: ActiveClusterModel[];
	onChangeSort: (search: FilterActiveCluster) => void;
	onUpdateData: (data: ActiveClusterModel[]) => void;
}
// declare var Plot: any;
declare var Plotly: any;

const TableChartActiveCluster: React.FC<Props> = (props) => {

	var xValues = props.data.map((item) => item.cluster).filter((item, index, array) => array.indexOf(item) === index).sort((a, b) => a - b);
	// xValues = xValues.filter((item, index, array) => array.indexOf(item) === index);
	// var xValues = props.data.map((item) => item.count).sort((a, b) => a - b);
	var data: Data[] = [];

	// xValues = xValues.sort((a, b) => a - b).filter((item, index, array) => array.indexOf(item) === index);
	var all_description = props.data.map((item) => item.activity_field_description).filter((item, index, array) => array.indexOf(item) === index).sort((a, b) => a[0] < b[0] ? 1 : 0);
	// all_description = all_description.filter((item, index, array) => array.indexOf(item) === index);

	for (let index_d = 0; index_d < all_description.length; index_d++) {
		// xValues[index] = 'Cluster ' + xValues[index];
		var description = all_description[index_d];

		var description_list = props.data.filter((item) => item.activity_field_description == description);
		var list_graph = []
		for (let index_c = 0; index_c < xValues.length; index_c++) {
			var total_cluster = props.data.filter((item) => item.cluster == xValues[index_c]).map((item) => item.count).reduce((a, b) => a + b, 0);
			var cluster = xValues[index_c];
			list_graph.push(description_list.filter((item) => item.cluster == cluster).map((item) => item.count)[0] / total_cluster*100 || null);


		}
		// var total = props.data.filter((item) => item.cluster == index).map((item) => item.count).reduce((a, b) => a + b, 0);

		data.push({
			x: xValues,
			y: list_graph,
			type: 'bar',
			marker: { color: colors_plotly()[index_d] },
			textposition: 'auto',
			name: description,
			// hovertemplate: 
			hovertemplate: `${description}<br>Value: %{y:.2f}%<extra></extra>`,

		});

	}
	// var xValues = ['Cluster 1', 'Cluster 2', 'Cluster 3']
	// var data = [
	// 	{
	// 		x: xValues,
	// 		y: [1, 2, 3],
	// 		type: 'bar',
	// 		name: 'Abacaxi',
	// 		textposition: 'auto',
	// 	},
	// 	{
	// 		x: xValues,
	// 		y: [4, 5, 6],
	// 		type: 'bar',
	// 		name: 'Memoria',
	// 		textposition: 'auto',
	// 	},
	// 	{
	// 		x: xValues,
	// 		y: [4, 5],
	// 		type: 'bar',
	// 		name: 'Memoria',
	// 		textposition: 'auto',
	// 	}
	// ] as Data;


	var layout = {
		barmode: 'relative',
		// title:'Ramo de Atividade x Cluster',
		xaxis: {
			title: 'Cluster',
			tickmode: 'linear',
		},
		yaxis: {
			title: 'Ramo de Atividade %',
		},
		title: {
			text: 'Ramo de Atividade x Cluster',
		}

	} as Layout;




	React.useEffect(() => {
		// var Plotlys =
		Plotly.newPlot('myDiv', data, layout, { showSendToCloud: true });

		// var data1 = [
		// 	{
		// 		z: [[1, 20, 30], [20, 1, 60], [30, 60, 1]],
		// 		type: 'heatmap'
		// 	}
		// ] as any;

		// Plotly.newPlot('myDiv1', data1);

	}, []);

	return (
		<>
			<div id="myDiv" />
			<div id="myDiv1" />
			{/* <Plot
				data={mapData}

				// layout={{ title: 'Quantidade de Clientes por Tipologia para cada Cluster' ,"barmode": "relative"}}
				layout={{  title: 'A Fancy Plot' }}
			/>
			<Plot
                    data={[{
                        x,
                        y,
                        z,

                        colorscale: [
                            [0, 'rgba(254, 73, 44, 0.9)'],
                            [0.5, 'rgba(255, 208, 0, 0.9)'],
                            [1, 'rgba(97, 201, 164, 0.9)']
                        ],
                        zmax: 12,
                        zmin: 0,
                        zsmooth: 'best',
                        type:  'heatmap' ,
                        showscale: false,
                        connectgaps: true,
                    }
                    ]}
                    layout={{
                        xaxis: {

                            type: 'date'
                        },
                    }}
                    ActiveClusteresizeHandler={true}
                    style={{ width: "100%", height: "100%" }}

                /> */}
		</>
	);
};

export default TableChartActiveCluster;
