import * as React from "react";

import { Card, Container } from "react-bootstrap";
import loadingGif from "../../assets/imagens/loading.gif";
import { useToast } from "../../core/contexts/toast";
import { useEffect, useState } from "react";
import Filter from "./filter";
import TableCNPJHashManager from "./table";
import { MessageType } from "../../core/config/message-type";
import { FilterCNPJHash, CNPJHashRepository } from "../../core/repositories/cnpj_hash.repository";
import { CNPJHashModel } from "../../core/model/cnpj_hash";
import "./index.scss";


var count: number = 0;

const CNPJHash: React.FC = () => {
  const { toastShow } = useToast();
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState<CNPJHashModel[]>([]);
  const [search, setSearch] = useState(new FilterCNPJHash());

  const _cnpj = new CNPJHashRepository();

  useEffect(() => {
    // getList(search);
  }, []);

  function getList(search: FilterCNPJHash) {
    search.page = 1;
    setSearch({ ...search });
    loadingList(search);
  }

  function changeSort(search: FilterCNPJHash) {
    setSearch({ ...search });
    loadingList(search);
  }

  function loadingList(search: FilterCNPJHash) {
    const _count = count++;
    setLoading(true);
    _cnpj.getFilter(search).then((data) => {
      if (_count === count - 1) {
        search.total = data.length
          ? (data[0] as any).total_records
          : data.length;
        setSearch({ ...search });
        setData(data);
        setLoading(false);
      }
    }).catch(() => {
      toastShow({
        title: "Erro!",
        subTitle: MessageType.ERROR_TRY,
        type: "error",
      });
    });
  }

  return (
    <Container className="CNPJHashManager max-98 pb-4">
      <Filter search={search} onChangeFilter={getList} />

      <Card className="card-d">
        <Card.Body>
          {loading ? (
            <div className="height-500">
              <div className="text-center loading align-vertical  ">
                <img className="width-150 height-150" src={loadingGif} alt="" />
              </div>
            </div>
          ) : (
            <TableCNPJHashManager
              search={search}
              onChangeSort={changeSort}
              onUpdateData={(dataUp:CNPJHashModel[]) => {
                setData([...dataUp]);
              }}
              data={data}
            />
          )}
        </Card.Body>
      </Card>
    </Container>
  );
};

export default CNPJHash;
