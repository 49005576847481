// @flow
import * as React from 'react';
import { Row, Col, Form } from 'react-bootstrap';
import { useToast } from '../../core/contexts/toast';
import { useState, useEffect } from 'react';
import { MessageType } from '../../core/config/message-type';
import { FilterLogApi } from '../../core/repositories/log_api.repository';
import { CustomerClusterModel } from '../../core/model/customer_cluster';
import { CustomerCityModel } from '../../core/model/customer_city';
import { CustomerModel } from '../../core/model/customer';
import { ProductCategoryModel } from '../../core/model/product_category';
import { CustomerClusterRepository } from '../../core/repositories/customer_cluster.repository';
import { CustomerCityRepository } from '../../core/repositories/customer_city.repository';
import { CustomerRepository } from '../../core/repositories/customer.repository';
import { ProductCategoryRepository } from '../../core/repositories/product_category.repository';
import { convertArrayToCsvText, getNameFilter } from '../../core/utils/functions';
import { FilterHeader } from '../../components/FilterHeader';
import 'react-datepicker/dist/react-datepicker.css';
import Switch from '../../components/Switch';

interface Props {
	search: FilterLogApi;

	onChangeFilter: (search: FilterLogApi) => void;
}

export const Filter: React.FC<Props> = (props) => {
	const { toastShow } = useToast();



	useEffect(() => {
		
	}, []);


	function handleClearFilter() {
		var selects = document.getElementsByTagName('select');
		for (var i = 0; i < selects.length; i++) {
			selects[i].value = '';
		}
		props.onChangeFilter(new FilterLogApi());
	}

	return (
		<div className="stick">

			<FilterHeader title="Log API" clearFilter={handleClearFilter} reload={() => { props.onChangeFilter(props.search) }}>
				<Row >
					<Col className="font-size-12" md={1}>
						<Form.Group className="mb-3 mr-3 SelectSearch">
							<Form.Label className="font-size-14">Sucesso ?</Form.Label>
							<br />
							<Switch
								checked={props.search.success}
								onChange={() => {
									props.search.success = !props.search.success;
									props.onChangeFilter(props.search);
								}}
							></Switch>
						</Form.Group>
					</Col>
				</Row>
			</FilterHeader>

		</div>
	);
};
