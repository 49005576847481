import { CollecionName } from '../config/collection-name';
import { CustomerSegmentationModel } from '../model/customer_segmentation';
import { Repository } from './repository';

export class FilterCustomerSegmentation {
	id = '';
	description = '';

	cluster = '';
	customer_code = '';
	customer_code_name = '';
	city: string = '';
	category_id = '';
	category_name = '';
	hide_customer: boolean = false;

	sort: Partial<CustomerSegmentationModel> = {};

	page = 1;
	pageSize = 20;
	total = 0;
}

export class CustomerSegmentationRepository extends Repository<CustomerSegmentationModel> {
	constructor() {
		super(CollecionName.CUSTOMER_SEGMENTATION);
	}
}
