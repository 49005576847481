import { CollecionName } from "../config/collection-name";
import { BasePriceCatalogEditModel } from "../model/base_price_catalog_edit";
import { Repository } from "./repository";

export class FilterBasePriceCatalogEdit {
  user_id: string = "";
  product_category_id: string = "";
  product_id: string = "";
  state_id: string = "";


  page = 1;
  pageSize = 60;
  total = 0;


}

export class BasePriceCatalogEditRepository extends Repository<BasePriceCatalogEditModel> {
  constructor() {
    super(CollecionName.BASE_PRICE_CATALOG_EDIT);
  }

 


  deleteAll(): Promise<Boolean> {
    return this.api.post(this.collectionName + "_delete").then((res: any) => {
      return res.data.success;
    });
  }
}
