import { CollecionName } from '../config/collection-name';
import { BaseModel } from '../model/base-model';
import { Repository } from './repository';


export class MesorregionRepository extends Repository<BaseModel> {
	constructor() {
		super(CollecionName.MESORREGION);
	}
}
