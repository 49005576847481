import axios from "axios";
import axiosRetry from "axios-retry";

const api = axios.create({
  baseURL: (process.env.REACT_APP_BASE_BACK || "") + "/api",
  withCredentials: true,  // habilita o envio de cookies
});

axiosRetry(api, {
  retries: 3,
  retryDelay: (retryCount) => {
    return retryCount * 1000;
  },
});

api.interceptors.request.use(
  function (config: any) {
    if (config.method === "post" && config.data) {
      Object.keys(config.data).forEach((res) => {
        if (config.data[res] === undefined || config.data[res] == null) {
          delete config.data[res];
        }
      });
    }
    return config;
  },
  function (error: any) {
    return Promise.reject(error);
  }
);

// const myCookie =
//   "access_token_cookie=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOjEsImlhdCI6MTcxNjQ4NjE1OSwibmJmIjoxNzE2NDg2MTU5LCJqdGkiOiJjYWM2MmY1OC0xYmUyLTRiNDItODQ3ZS1hZWQ1OWU0YTQ1YzgiLCJleHAiOjE3MTY0ODk3NTksInR5cGUiOiJhY2Nlc3MiLCJmcmVzaCI6ZmFsc2V9.MLC053HLNhTm3HIAxcw2CGFPIy4cqVWnRNu81Yrj7cE";

// // Faça uma requisição GET com o cookie manualmente adicionado ao cabeçalho
// api.get("/health", {
//   headers: {
//     Cookie: myCookie, // Envia o cookie diretamente
//   },
// });

export default api;
