import React from "react";
import { Container, Button } from "react-bootstrap";
import { FcBrokenLink } from "react-icons/fc";
import { useNavigate } from "react-router-dom";
import "./index.scss";

const Page404: React.FC = () => {
  const navigate = useNavigate();



  return (
    <Container className="text-center mt-5 Page404">
      <div>
        <FcBrokenLink className="icon" />
      </div>
      <div className="text-center error404">
        <h3>
          <b>Está página não está disponível</b>
        </h3>
        <p>
          O link pode não estar funcionando ou a página pode não existir.
          Verifique se o link está correto e tente novamente.
        </p>
        <Button className="btn-primary" onClick={() => { navigate("/") }}>
          Voltar a página principal
        </Button>
      </div>
    </Container>
  );
};

export default Page404;
