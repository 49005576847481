import { CollecionName } from "../config/collection-name";
import { ProductSectionModel } from "../model/product_section";
import { Repository } from "./repository";

export class FilterProductSection {

  id = "";
  description = "";

  sort: Partial<ProductSectionModel> = {
    // distribution_center: 'asc',
  };

  page = 1;
  pageSize = 20;
  total = 0;
}

export class ProductSectionRepository extends Repository<ProductSectionModel> {
  constructor() {
    super(CollecionName.PRODUCT_SECTION);
  }
  
	public async getAllByFilter(departmentIds:string): Promise<ProductSectionModel[]> {
		return this.api.get(`${this.collectionName}?department_id=${departmentIds}`).then((res) => {
			return res.data;
		});
	}
}
