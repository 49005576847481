import * as React from "react";
import { useEffect } from "react";
import { FaSort, FaSortUp, FaSortDown } from "react-icons/fa";
import "./index.scss";

interface Props {
  sort?: any;
  className?: string;
  classNameTh?: string;
  width?: any;
  children?: any,
  colSpan?: number;
  rowSpan?: number
  onSort?: (value: string) => void;
}

const TableRow: React.FC<Props> = (props) => {
  const [sort, setSort] = React.useState<string>();

  useEffect(() => {
    setSort(props.sort ? props.sort : undefined);
  }, [props.sort]);


  function sortOnClick() {
    let aux = null;
    if (props.onSort) {
      if (sort === "desc") {
        aux = undefined;
      } else {
        aux = sort === undefined ? "asc" : "desc";
      }
      setSort(aux);
      props.onSort(aux ? aux : "");
    }
  }

  return (
    <th
      rowSpan={props.rowSpan}
      colSpan={props.colSpan}
      style={{ width: props.width }}
      onClick={sortOnClick}
      className={(props.onSort !== undefined && "pointer") + " table-row "+ (props.classNameTh || "")
      }
    >
      <div className={(props?.className || "") + " vertical"}>
        <div className="w-100">{props.children}</div>
        {props.onSort !== undefined && (
          <div className="sort-absolute">
            {sort === undefined ? (
              <FaSort></FaSort>
            ) : sort !== "asc" ? (
              <FaSortUp className="text-primary"></FaSortUp>
            ) : (
              <FaSortDown className="text-primary"></FaSortDown>
            )}
          </div>
        )}
      </div>
    </th >
  );
};

export default TableRow;
