import { Toast, ToastContainer, Container } from "react-bootstrap";
import { FaCheckCircle, FaInfoCircle, FaBug } from "react-icons/fa";
import pt from 'date-fns/locale/pt-BR';
import React, { useEffect } from "react";
import { useToast } from "../../core/contexts/toast";
import Header from "../Navbar";
import { Outlet } from "react-router-dom";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import moment from "moment";
import "./index.scss";

import 'moment/locale/pt'
registerLocale('pt-BR', pt)

moment.defineLocale('pt-BR', {
	relativeTime: {
		future: 'in %s',
		past: '%s ago',
		s: function (number, withoutSuffix) {
			return withoutSuffix ? 'now' : 'a few seconds';
		},
		m: '1m',
		mm: '%dm',
		h: '1h',
		hh: '%dh',
		d: '1d',
		dd: '%dd',
		M: '1mth',
		MM: '%dmth',
		y: '1y',
		yy: '%dy',
	},
});

const MainLayout: React.FC = () => {
  const { toast, toastClear } = useToast();

  useEffect(() => {
    setDefaultLocale("pt-BR");
    // moment.lang("pt")
  }, []);
  return (
    <div>
      <Header />
      <div className="main-layout ">
        <Container className="height padding-top-60">
          <div>
            <ToastContainer position="bottom-end" className="p-3">
              <>
                {toast && (
                  <Toast
                    onClose={() => toastClear()}
                    // show={!toast}
                    delay={6000}
                    autohide
                    // bg={toast?.color}
                    className={"toast-" + toast.type}
                  >
                    <div className="icons align-vertical margin-left-5 font-size-24">
                      <div className="mx-2">
                        {toast.type === "info" && <FaInfoCircle></FaInfoCircle>}
                        {toast.type === "success" && (
                          <FaCheckCircle></FaCheckCircle>
                        )}
                        {toast.type === "warning" && (
                          <FaInfoCircle></FaInfoCircle>
                        )}
                        {toast.type === "error" && (
                          <FaBug className="text-danger"></FaBug>
                        )}
                      </div>
                    </div>
                    <div>
                      <Toast.Header>
                        <strong className="me-auto">{toast?.title}</strong>
                      </Toast.Header>
                      <Toast.Body>
                        <div>{toast?.subTitle}</div>
                      </Toast.Body>
                    </div>
                  </Toast>
                )}
              </>
            </ToastContainer>
            <Outlet />
          </div>
        </Container>
        {/* <footer className="text-center font-size-12 ">
          <Alert.Link className="text-primary" href="https://analytics2go.com">
            <FaRegCopyright />
            2022 Analytics2Go
          </Alert.Link>
        </footer> */}
      </div>
    </div>
  );
};

// interface RouteProps extends DomRouteProps {
//   layout?: React.FC;
// }

// export class RouteLayout extends DomRoute<RouteProps> {
//   static defaultProps: RouteProps = {
//     layout: MainLayout,
//   };

//   render() {
//     const { layout, component, path, exact } = this.props;

//     return (
//       <DomRoute
//         path={path}
//         exact={exact}
//         render={(props) => {
//           return React.createElement(
//             layout!,
//             props as React.Attributes,
//             React.createElement(component!, props)
//           );
//         }}
//       />
//     );
//   }
// }

export default MainLayout;
