import { CollecionName } from '../config/collection-name';
import { BasicFilter } from '../model/base-model';
import { LogApiModel } from '../model/log_api';
import { Repository } from './repository';

export class FilterLogApi extends BasicFilter<LogApiModel> {
	success: boolean = false;
}

export class LogApiRepository extends Repository<LogApiModel> {
	constructor() {
		super(CollecionName.LOG_API);
	}
}
