import * as React from 'react';
import { useEffect, useState } from 'react';
import { Button, Form, Modal, Spinner } from 'react-bootstrap';
import { MessageType } from '../../core/config/message-type';
import { useAuth } from '../../core/contexts/auth';
import { CNPJHashModel } from '../../core/model/cnpj_hash';
import { CNPJHashRepository } from '../../core/repositories/cnpj_hash.repository';
import { IMaskInput } from "react-imask";
import { validarCNPJ } from '../../core/utils/functions';

interface Props {
	cnpj?: CNPJHashModel;
	show: boolean;
	title?: string;
	onHide: () => void;
	onRefresh: () => void;
}

export const ModalCNPJHash: React.FC<Props> = (props) => {
	const [validated, setValidated] = useState(false);
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState(``);
	const { user } = useAuth();

	const _cnpj = new CNPJHashRepository();

	React.useEffect(() => {
		setValidated(false);
		setError('');
	}, [props.show]);

	async function handleSubmit(event: any) {
		setValidated(true);
		const form = event.currentTarget;
		event.preventDefault();
		// event.stopPropagation();
		setLoading(true);

		try {
			if (validarCNPJ(form[0].value)) {
				setError("CNPJ inválido!");
				return;
			}
			const cnpjModel = new CNPJHashModel();
			cnpjModel.description = form[0].value;
			// cnpjModel.last_name = form[1].value;
			// cnpjModel.email = form[2].value;
			// if (props.user) {
			// 	cnpjModel.id = props.user.id;
			// } else {
			// 	if (user) {
			// 		cnpjModel.user_id_creator = user.id;
			// 	}
			// }
			await _cnpj.add(cnpjModel);

			props.onRefresh();
			props.onHide();

			setLoading(false);
		} catch (error: any) {
			setLoading(false);
			if (error.toJSON && error.toJSON().status == 406) {
				setError('O CNPJ já existe!');
			} else if (error && typeof error == 'string') {
				setError(error);
			} else {
				setError(MessageType.ERROR_TRY);
			}
		}
	}

	return (
		<>
			<Modal centered aria-labelledby="contained-modal-title-vcenter" {...props}>
				<Modal.Header closeButton>
					<Modal.Title id="contained-modal-title-vcenter" className="text-center">
						{props.cnpj ? 'Editar' : 'Criar Novo'} CNPJ Hash
					</Modal.Title>
				</Modal.Header>
				<Modal.Body>
					<Form id="myform" validated={validated} onSubmit={handleSubmit}>
						<Form.Group controlId="name">
							<Form.Label>CNPJ</Form.Label>
							<Form.Control
								mask="00.000.000/0000-00"
								as={IMaskInput}
								defaultValue={props.cnpj?.description} 
								type="tel" 
								placeholder="Digite o CNPJ..." />
						</Form.Group>

						{error &&
							<div className="error text-danger mb-2 font-size-12">
								<label> {error}</label>
							</div>
						}

					</Form>
				</Modal.Body>
				<Modal.Footer>
					<Button variant="secondary" onClick={() => props.onHide()}>
						Fechar
					</Button>
					<Button disabled={loading} type="submit" form="myform">
						{loading ? <Spinner size="sm" animation="border" role="status"></Spinner> : props.cnpj ? 'Salvar' : 'Criar'}
					</Button>
				</Modal.Footer>
			</Modal>
		</>
	);
};
