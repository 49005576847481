import * as React from 'react';
import { Annotations, Data, Layout } from 'plotly.js';
import { ChartCohortModel } from '../../core/model/chart_cohort';
import { FilterChartCohort } from '../../core/repositories/chart_cohort.repository';
import moment from 'moment';

interface Props {
	search: FilterChartCohort;
	data: ChartCohortModel;
	onChangeSort: (search: FilterChartCohort) => void;
	onUpdateData: (data: ChartCohortModel) => void;
}
// declare var Plot: any;
declare var Plotly: any;

const ChartChartCohort: React.FC<Props> = (props) => {

	const [title, setTitle] = React.useState("");

	var xValues = props.data.xValues;
	var yValues = props.data.yValues;
	var zValues: number[][] = [];
	var zValuesAbsolute = [...props.data.zValues];
	var zValuesPorcent = [...props.data.zValues];

	// normalize z values
	for (var i = 0; i < zValuesAbsolute.length; i++) {
		zValuesAbsolute[i] = [...zValuesAbsolute[i]];
		zValuesPorcent[i] = [...zValuesPorcent[i]];
		var maxZ = Math.max.apply(null, zValuesAbsolute[i]);

		for (var j = 0; j < zValuesAbsolute[i].length; j++) {
			zValuesPorcent[i][j] = (zValuesPorcent[i][j] / maxZ) * 100;
			zValuesAbsolute[i][j] = (zValuesAbsolute[i][j] * 1);


			var aux: any = null;
			if (zValuesAbsolute[i][j] == 0) {
				aux = "n/a";
				zValuesAbsolute[i][j] = aux;
			}
			var value = zValuesAbsolute[i][j];
			if (typeof value == "number") {
				if (props.search.type == "retention") {
					aux = value % 1 !== 0 ? value.toFixed(2) : value.toFixed(0);
				} else {
					aux = value.toFixed(2);
				}
			}
			zValuesAbsolute[i][j] = aux
		}
	}
	zValues = props.search.absolute ? zValuesAbsolute : zValuesPorcent;
	var data = [{
		x: xValues,
		y: yValues,
		z: zValues,
		type: 'heatmap',
		customdata: props.search.absolute ? zValuesPorcent : zValuesAbsolute ,
		hovertemplate:  props.search.absolute ? 
		`Período: %{x}<br>Mês: %{y}<br>Porcentagem: %{customdata:,.2f}%<br> Absoluto: %{z:,.0f} <extra></extra>`:
		`Período: %{x}<br>Mês: %{y}<br>Porcentagem: %{z:,.2f}%<br> Absoluto: %{customdata} <extra></extra>`,
		colorscale: [
			[0, '#e6e6e6'],
			[0.5, '#ff0000'],
			[1, '#000000']
		],

		showscale: true,


	}] as Data[];

	var title1 = ""
	if (props.search.cluster) {
		if (props.search.cluster.length == 1)
			title1 = 'Retenção do Cluster ' + props.search.cluster.toString()
		else
			title1 = 'Retenção dos Cluster ' + props.search.cluster.toString()

	} else {
		title1 = 'Retenção de todos os Cluster'
	}
	title1 += props.search.absolute ? " (Absoluto)" : " (%)";



	var layout = {
		margin: {
			t: 32,
		},
		xaxis: {
			title: 'Período do mês',
			tickmode: 'linear',
		},
		yaxis: {
			title: 'Mês',
			autorange: 'reversed',
		},

		annotations: [
		] as Annotations[],
		hoverlabel: {
			bgcolor: 'white',
			font: {
				color: 'black'
			}
		},
		template: {
			data: {
				heatmap: [
					{
						colorscale: [
							[0, '#e6e6e6'],
							[0.5, '#ff0000'],
							[1, '#000000']
						]
					}
				]
			}
		},

	} as Layout;

	var id = Math.random().toString(36).substr(2, 9);

	React.useEffect(() => {

		for (var i = 0; i < yValues.length; i++) {
			for (var j = 0; j < xValues.length; j++) {
				var result = {
					x: xValues[j].toString(),
					y: yValues[i].toString(),
					text: Number.parseFloat(zValues[i][j].toString()).toFixed(2),
					showarrow: false,
					font: {
						color: 'white'
					},
				} as Partial<Annotations>;
				layout.annotations.push(result);
			}
		}
		Plotly.newPlot('myDiv' + id, data, layout, { showSendToCloud: true });
		// var myPlot:any = null , hoverInfo:any = null;
		// myPlot = document.getElementById('myDiv');
		// hoverInfo = document.getElementById('hoverinfo')

		// myPlot.on('plotly_hover', function (data:any) {
		// 	console.warn(data);
		// 	hoverInfo.innerHTML = data.points[0].customdata;
		// })
		// myPlot.on('plotly_unhover', function (data:any) {
		// 	hoverInfo.innerHTML = '';
		// });

		setTitle(title1);
	}, []);

	return (
		<>
			{props.data && (
				<div className="h-100 align-vertical  font-size-15  text-center " >

					<b>{title}</b>
					<div className="position-absolute right-12">

						Última atualização do modelo: <b>{moment(props.data.date_creation).format('DD/MM/YYYY')}</b>
					</div>
				</div>
			)}
			<div id={"myDiv" + id} className='myDiv' />
		</>
	);
};

export default ChartChartCohort;
