// @flow
import * as React from 'react';
import { Row, Col, OverlayTrigger, Tooltip, Table } from 'react-bootstrap';
import { useToast } from '../../core/contexts/toast';
import { useState, useEffect, FC } from 'react';
import SelectSearch from '../../components/SelectSearch';
import { MessageType } from '../../core/config/message-type';
import { CompetitorModel } from '../../core/model/competitor';
import { CompetitorRepository } from '../../core/repositories/competitor.repository';
import { competitorTypeList, convertArrayToCsvText, getNameFilter, matchingRatingList, matchingStatusList } from '../../core/utils/functions';
import { FilterHeader } from '../../components/FilterHeader';
import { SearchInput } from '../../components/SearchInput';
import { FaInfoCircle } from 'react-icons/fa';
import { FilterMatchingValidations } from '../../core/repositories/matching_validations.repository';

interface Props {
	search: FilterMatchingValidations;

	onChangeFilter: (search: FilterMatchingValidations) => void;
}

export const Filter: React.FC<Props> = (props) => {
	const { toastShow } = useToast();
	const [competitorList, setCompetitorList] = useState<CompetitorModel[]>([]);

	useEffect(() => {
		try {
			new CompetitorRepository().getAll().then((res) => {
				setCompetitorList(res);
			});
		} catch (error) {
			toastShow({
				title: 'Erro!',
				subTitle: MessageType.ERROR_TRY,
				type: 'error',
			});
		}
	}, []);

	return (
		<div className="stick">
			<FilterHeader
				title={
					<label className="MatchingValidations">
						Validação de Matching &nbsp;
						<OverlayTrigger
							placement="bottom"
							

							//show={true}

							overlay={
								<Tooltip className='width-600'>
									Essa tela tem como objetivo validar os casamentos de produtos entre a Laredo e os concorrentes, realizados pelo modelo de Product Matching.
									<Table className="mt-2" striped bordered hover variant="dark">
										<tbody>
											<tr>
												<td>Concorrente ID</td>
												<td>Número do CNPJ do concorrente criptografado</td>
											</tr>
											<tr>
												<td>NCM</td>
												<td>Nomenclatura Comum do Mercosul do concorrente</td>
											</tr>
											<tr>
												<td>Produto Concorrente</td>
												<td>Código e descrição do produto concorrente</td>
											</tr>
											<tr>
												<td>Tipo de Concorrente</td>
												<td>
													GV - Grande Varejista
													<br />
													CD - Concorrente Direto
													<br />
													IL - Indústria Local
												</td>
											</tr>
											<tr>
												<td>Produto Laredo</td>
												<td>Código e descrição do produto Laredo</td>
											</tr>
											<tr>
												<td>Tipo de Matching</td>
												<td>Indica se o produto concorrente casou com um produto Laredo especifico (exato) ou similar (parecido)</td>
											</tr>
											<tr>
												<td>Avaliação do Modelo</td>
												<td>Qual foi a classificação do modelo matemático para esse casamento</td>
											</tr>
											<tr>
												<td>Última Atualização</td>
												<td>Quando foi a última atualização do usuário nesse casamento</td>
											</tr>
											<tr>
												<td>Ações do usúario</td>
												<td>
													Confirmar: Confirmar o casamento correto
													<br />
													<br />
													Rejeitar: Rejeitar o casamento que não tem como corrigir
													<br />
													<br />
													Reverter: Desfazer a ação tomada sobre o casamento
												</td>
											</tr>
										</tbody>
									</Table>
								</Tooltip>
							}
						>
							<label className="click text-primary vertical-50 margin-top--1">
								<FaInfoCircle />
							</label>
						</OverlayTrigger>
					</label>
				}
				clearFilter={() => {
					var selects = document.getElementsByTagName('select');
					for (var i = 0; i < selects.length; i++) {
						selects[i].value = '';
					}
					props.onChangeFilter(new FilterMatchingValidations());
				}}
				reload={() => {
					props.onChangeFilter(props.search);
				}}
			>
				<Row>
					<Col md={2}>
						<SelectSearch
							name="Concorrente ID"
							selectId="id"
							selectName="description"
							className="w-100"
							multiple={true}
							value={props.search.competitor_id}
							options={competitorList}
							onChange={(value) => {
								if (Array.isArray(value)) props.search.competitor_id = convertArrayToCsvText(value);
								else props.search.competitor_id = value.toString();
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>
					<Col md={2}>
						<SearchInput
							description="Concorrente: Cód. Produto"
							value={props.search.competitor_product_id}
							onChange={(event) => {
								props.search.competitor_product_id = event;
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>
					<Col md={4}>
						<SearchInput
							description="Concorrente: Descrição"
							value={props.search.competitor_product_description}
							onChange={(event) => {
								props.search.competitor_product_description = event;
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>
					<Col md={2}>
						<SelectSearch
							name="Concorrente: Tipo"
							selectId="id"
							selectName="description"
							multiple={false}
							required={true}
							value={props.search.competitor_issuer_type}
							options={competitorTypeList}
							onChange={(value) => {
								value = value.toString();
								props.search.competitor_issuer_type = value;
								props.search.competitor_issuer_type_description = getNameFilter(competitorTypeList, value, 'id', 'description');
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col md={2}>
						<SelectSearch
							name="Avaliação do Modelo"
							selectId="id"
							selectName="description"
							multiple={true}
							value={props.search.match_rating}
							options={matchingRatingList}
							onChange={(value) => {
								value = value.toString();
								props.search.match_rating = value;
								props.search.match_rating_description = getNameFilter(matchingRatingList, value, 'id', 'description');
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col md={2}>
						<SearchInput
							description="NCM"
							value={props.search.competitor_ncm}
							onChange={(event) => {
								props.search.competitor_ncm = event;
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col md={2}>
						<SearchInput
							description="Laredo: Cód. Produto"
							value={props.search.product_id}
							type="number"
							onChange={(event) => {
								props.search.product_id = event;
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col md={4}>
						<SearchInput
							description="Laredo: Descrição"
							value={props.search.product_description}
							onChange={(event) => {
								props.search.product_description = event;
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>
					<Col md={2}>
						<SelectSearch
							name="Status"
							selectId="id"
							selectName="description"
							required={true}
							value={props.search.match_status}
							options={matchingStatusList}
							onChange={(value) => {
								props.search.match_status = value;
								props.search.match_status_description = getNameFilter(matchingStatusList, value, 'id', 'description');

								props.onChangeFilter(props.search);
							}}
						/>
					</Col>

					<Col md={2}>
						<SelectSearch
							name="Tipo de Matching"
							selectId="id"
							selectName="description"
							multiple={false}
							required={true}
							value={props.search.is_similar}
							options={[
								{ id: '', description: 'Todos' },
								{ id: true, description: 'Similar' },
								{ id: false, description: 'Específico' },
							]}
							onChange={(value) => {
								props.search.is_similar = value == '' ? null : value;
								props.onChangeFilter(props.search);
							}}
						/>
					</Col>
				</Row>
			</FilterHeader>
		</div>
	);
};
