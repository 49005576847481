import axios, { AxiosInstance } from "axios";
import { MessageType } from "../config/message-type";
import { UserModel } from "../model/user";
import api from "./api";

class ApiAuth {
  private api: AxiosInstance;

  constructor() {
    this.api = axios.create();
  }

  token(email: string): Promise<string> {
    // return Promise.resolve(new UserModel())
    return api.post("/token", { email: email }).then((res) => {return res.data});
  }

  getInfoByEmail(email: string): Promise<UserModel> {
    return new Promise((resolve, reject) => {
      // return resolve(new UserModel())
      return api.get(`/user_by_email?email=${email}`).then((res) => {
        if (res.data) {
          return resolve(res.data);
        } else {
          return reject(MessageType.ERROR);
        }
      }).catch((error) => {
        return reject();
      });
    });
  }

  checkEmail(email: string): Promise<UserModel> {
    return new Promise((resolve, reject) => {
      return api
        .get(`/check_email?email=${email}`)
        .then((res) => {
          if (res.data && res.data == "True") {
            return resolve(res.data);
          } else {
            return reject(MessageType.UNAUTHORIZED);
          }
        })
        .catch((error) => {
          return reject();
        });
    });
  }


  async current(): Promise<UserModel | null> {

    try {

      var data = await this.api.get("/.auth/me")
      if (data.data.clientPrincipal) {
        const user = new UserModel();
        user.email = data.data.clientPrincipal.userDetails;
        // await this.token(user.email) 

        return user;
      } else {
        return null;
      }
    } catch (error) {
      return null;
    }
  }
}

export default new ApiAuth();
