import React from 'react';
import { LoginSuccess } from '../pages/LoginSuccess';
import { Logout } from '../pages/Logout';
import { Login } from '../pages/Login';
import Page404 from "../pages/Page404";
import { Routes, Route, Navigate } from 'react-router-dom';


const AuthRoutes: React.FC = () => (
  <Routes>
    <Route path="/login" element={<Login/>} />
    <Route path="/loginsuccess" element={<LoginSuccess/>} />
    <Route path="/404" element={<Page404/>} />
    <Route path="/logout" element={<Logout/>} />
    <Route path="*" element={<Navigate to="/login" replace />} />

  </Routes>
);

export default AuthRoutes;
