import { CollecionName } from '../config/collection-name';
import { CustomerCityModel } from '../model/customer_city';
import { Repository } from './repository';

export class FilterCustomerCity {
	id = '';
	description = '';

	sort: Partial<CustomerCityModel> = {
		// distribution_center: 'asc',
	};

	page = 1;
	pageSize = 20;
	total = 0;
}

export class CustomerCityRepository extends Repository<CustomerCityModel> {
	constructor() {
		super(CollecionName.CUSTOMER_CITY);
	}
}
