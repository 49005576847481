import { CollecionName } from '../config/collection-name';
import { CustomerClusterModel } from '../model/customer_cluster';
import { Repository } from './repository';

export class FilterCustomerCluster {
	id = '';
	description = '';

	sort: Partial<CustomerClusterModel> = {
		// distribution_center: 'asc',
	};

	page = 1;
	pageSize = 20;
	total = 0;
}

export class CustomerClusterRepository extends Repository<CustomerClusterModel> {
	constructor() {
		super(CollecionName.CUSTOMER_CLUSTER);
	}
}
