import { CollecionName } from '../config/collection-name';
import { CompetitorModel } from '../model/competitor';
import { Repository } from './repository';

export class FilterCompetitor {
	id = '';
	description = '';

	sort: Partial<CompetitorModel> = {
		// distribution_center: 'asc',
	};

	page = 1;
	pageSize = 20;
	total = 0;
}

export class CompetitorRepository extends Repository<CompetitorModel> {
	constructor() {
		super(CollecionName.COMPETITOR);
	}
}
