import { CollecionName } from "../config/collection-name";
import { ProductModel } from "../model/product";
import { Repository } from "./repository";

export class FilterProduct {

  id = "";
  description = "";

  sort: Partial<ProductModel> = {
    // distribution_center: 'asc',
  };

  page = 1;
  pageSize = 20;
  total = 0;
}

export class ProductRepository extends Repository<ProductModel> {
  constructor() {
    super(CollecionName.PRODUCT);
  }


  async changeStatus(user_id: number, ids: number[], status: boolean, change_all: boolean) {
    return this.api.post(this.collectionName + "_status", { user_id, ids, status: status ? 1 : 0, change_all }).then((res) => {
      return res.data;
    });
  }

  async updateList(user_id: number, ids: number[], override_bid: number, change_all: boolean) {
    return this.api.post(this.collectionName + "_list_update", { user_id, ids, override_bid, change_all }).then((res) => {
      return res.data;
    });
  }
  
  async getIndex(data: any): Promise<ProductModel> {
    return this.getFilter(data,"_index").then((res:any)=>{return res;})
  }
}
