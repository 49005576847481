import * as React from 'react';
import { useEffect, useState } from 'react';
import { Container, Card, Button, Spinner } from 'react-bootstrap';
import { useToast } from '../../core/contexts/toast';
import { Filter } from './filter';
import { MessageType } from '../../core/config/message-type';
import { TableMatchingValidations } from './table';
import { ProductModel } from '../../core/model/product';
import { ProductRepository } from '../../core/repositories/product.repository';
import { MatchingValidationsModel } from '../../core/model/matching_validations';
import { FilterMatchingValidations, MatchingValidationsRepository } from '../../core/repositories/matching_validations.repository';
import { FaDownload } from 'react-icons/fa';
import loadingGif from '../../assets/imagens/loading.gif';
import './index.scss';

var count: number = 0;

export const MatchingValidations: React.FC = () => {
	const [loading, setLoading] = useState(true);

	const { toastShow } = useToast();
	const [search, setSearch] = useState(new FilterMatchingValidations());
	const [productList, setProductList] = useState<ProductModel[]>([]);
	const [lastUpdate, setLastUpdate] = useState<Date|undefined>(undefined);
	const [data, setData] = useState<MatchingValidationsModel[]>([]);
	const [loadingBtnDownload, setLoadingBtnDownload] = useState(false);

	const _matchingValidationsRepository = new MatchingValidationsRepository();

	useEffect(() => {
		getList(search);
		new ProductRepository().getAll().then((res) => {
			setProductList(res);
		});
		_matchingValidationsRepository.getLastUpdate().then((res) => {
			setLastUpdate(res);
		})

	}, []);

	function getList(search: FilterMatchingValidations) {
		search.page = 1;
		setSearch({ ...search });
		loadingList(search);
	}
	function changeSort(search: FilterMatchingValidations) {
		setSearch({ ...search });
		loadingList(search);
	}

	function loadingList(search: FilterMatchingValidations) {
		const _count = count++;
		setLoading(true);

		_matchingValidationsRepository.getFilter(search).then((data) => {
			if (_count === count - 1) {
				search.total = data.length ? data[0].total_records : data.length;
				setSearch({ ...search });
				setData(data);
				setLoading(false);
			}
		}).catch(() => {
			setData([]);
			setLoading(false);
			toastShow({
				title: 'Erro!',
				subTitle: MessageType.ERROR_TRY,
				type: 'error',
			});
		});
	}
	function changeData(item: MatchingValidationsModel, index: number) {
		data[index] = item;
		setData([...data]);
	}

	function removeData(index: number) {
		data.splice(index, 1);
		setData([...data]);
	}

	function download() {
		setLoadingBtnDownload(true);
		_matchingValidationsRepository.download(search).then((req) => {
			var blob = new Blob([req.data], {
				type: req.headers['content-type'],
			});
			const link = document.createElement('a');
			link.href = window.URL.createObjectURL(blob);
			link.download = `Manual Matching ${new Date().toISOString()}.csv`;
			link.click();
			setLoadingBtnDownload(false);
		}).catch(() => {
			setLoadingBtnDownload(false);
			toastShow({
				title: 'Erro!',
				subTitle: MessageType.ERROR_TRY,
				type: 'error',
			});
		});
	}

	return (
		<Container className="MatchingValidations max-98">
			<Filter search={search} onChangeFilter={getList} />
			{/* <Button className="download-bnt" disabled={loadingBtnDownload} onClick={download} variant="secondary">
				{loadingBtnDownload ? <Spinner size="sm" animation="border" role="status" /> : <FaDownload />}
			</Button> */}
			<Card className="card-d">
				<Card.Body>
					{loading ? (
						<div className="height-500">
							<div className="text-center loading align-vertical  ">
								<img className="width-150 height-150" src={loadingGif} alt="" />
							</div>
						</div>
					) :
						<TableMatchingValidations
							search={search}
							productList={productList}
							onChangeSort={changeSort}
							onChangeData={changeData}
							onRemoveData={removeData}
							lastUpdate={lastUpdate}
							data={data}
						/>
					}
				</Card.Body>
			</Card>
		</Container>
	);
};
