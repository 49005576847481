import * as React from 'react';
import { Badge, Table } from 'react-bootstrap';
import TablePagination from '../../components/TablePagination';
import TableRow from '../../components/TableRow';
import { competitorTypeList, get_matching_rating_description, get_matching_status_description } from '../../core/utils/functions';
import { ProductMatchingModel } from '../../core/model/product_matching';
import { FilterProductMatching } from '../../core/repositories/product_matching.repository';
import moment from 'moment';

interface Props {
	search: FilterProductMatching;
	data: ProductMatchingModel[];
	onChangeSort: (search: FilterProductMatching, loading: boolean) => void;
}

export const TableProductMatching: React.FC<Props> = (props) => {
	function changeSort(value: string, prop: string) {
		const aux: any = {};
		aux[prop] = value;
		props.search.sort = aux;
		props.onChangeSort(props.search, true);
	}

	return (
		<>
			{props.data.length > 0 && (
				<div className="text-end font-size-15">
					Última atualização do modelo: <b>{moment(props.data[0].date_creation).format('DD/MM/YYYY')}</b>
				</div>
			)}
			<Table striped bordered hover size="sm">
				<thead>
					<tr>
						<TableRow
							classNameTh='min-width-145'
							sort={props.search.sort.department_description}
							onSort={(ev: any) => changeSort(ev, 'department_description')}
						>Departamento</TableRow>
						<TableRow
							sort={props.search.sort.section_description}
							onSort={(ev: any) => changeSort(ev, 'section_description')}
						>Seção</TableRow>
						<TableRow
							sort={props.search.sort.category_description}
							onSort={(ev: any) => changeSort(ev, 'category_description')}
						>Categoria</TableRow>
						<TableRow
							sort={props.search.sort.product_description}
							onSort={(ev: any) => changeSort(ev, 'product_description')}
						>Produto</TableRow>

						<TableRow
							sort={props.search.sort.competitor_product_description}
							onSort={(ev: any) => changeSort(ev, 'competitor_product_description')}
						>Produto Concorrente
						</TableRow>
						<TableRow
							sort={props.search.sort.competitor_id}
							onSort={(ev: any) => changeSort(ev, 'competitor_id')}
						>Concorrente ID
						</TableRow>

						<TableRow
							sort={props.search.sort.match_rating}
							onSort={(ev: any) => changeSort(ev, 'match_rating')}
						>Avaliação <br />do Modelo
						</TableRow>


						<TableRow
							classNameTh='min-width-85'
							sort={props.search.sort.match_status}
							onSort={(ev: any) => changeSort(ev, 'match_status')}
						>Status
						</TableRow>

						<TableRow
							classNameTh='min-width-100'
							sort={props.search.sort.is_similar}
							onSort={(ev: any) => changeSort(ev, 'is_similar')}

						>Tipo de <br /> Matching</TableRow>

					</tr>
				</thead>
				<tbody>
					{props.data.length === 0 && (
						<tr className="table-row-header text-center">
							<td colSpan={14}> Nenhum registro encontrado! </td>
						</tr>
					)}
					{props.data.map((_, index) => (
						<tr className="text-center" key={index}>
							<td>{_.department_description}</td>
							<td>{_.section_description}</td>
							<td>{_.category_description}</td>
							<td>
								[<b>{_.product_id}</b>] {_.product_description}
							</td>
							<td>
								[<b>{_.competitor_product_id}</b>] {_.competitor_product_description}

							</td>
							<td>{_.competitor_id}
								<Badge pill className="ml-1" bg="primary" title={_.competitor_issuer_type ? competitorTypeList.find(x => x.id == _.competitor_issuer_type)?.description : ""}>
									{_.competitor_issuer_type}
								</Badge>

							</td>
							<td>
								{
									_.match_rating == null ? 'N/A' :
										get_matching_rating_description(_.match_rating)}</td>
							<td>{get_matching_status_description(_.match_status)}</td>
							{/* Similar / Específico */}
							<td className='text-center'>
								{_?.is_similar ? "Similar" : "Específico"}

							</td>

						</tr>
					))}
				</tbody>
			</Table>
			<div>
				<TablePagination
					onChangePage={(value) => {
						props.search.page = value;
						props.onChangeSort(props.search, true);
					}}
					currentPage={props.search.page}
					pageSize={props.search.pageSize}
					count={props.search.total}
				></TablePagination>
			</div>
		</>
	);
};
