import { CollecionName } from "../config/collection-name";
import { ProductSubcategoryModel } from "../model/product_subcategory";
import { Repository } from "./repository";

export class FilterProductSubcategory {
  id = "";
  description = "";

  sort: Partial<ProductSubcategoryModel> = {
    // distribution_center: 'asc',
  };

  page = 1;
  pageSize = 20;
  total = 0;
}

export class ProductSubcategoryRepository extends Repository<ProductSubcategoryModel> {
  constructor() {
    super(CollecionName.PRODUCT_SUBCATEGORY);
  }
}
