import * as React from 'react';
import { Badge, Button, OverlayTrigger, Spinner, Table, Tooltip } from 'react-bootstrap';
import TablePagination from '../../components/TablePagination';
import TableRow from '../../components/TableRow';
import { FaCheck, FaInfoCircle, FaUndo, FaEdit, FaTrash } from 'react-icons/fa';
import { ProductModel } from '../../core/model/product';
import { useToast } from '../../core/contexts/toast';
import { ModalMatchingValidations } from './modal';
import { useAuth } from '../../core/contexts/auth';
import moment from 'moment';
import { IoClose } from 'react-icons/io5';
import { MatchingValidationsModel } from '../../core/model/matching_validations';
import { FilterMatchingValidations, MatchingValidationsRepository } from '../../core/repositories/matching_validations.repository';
import { competitorTypeList, get_matching_rating_description } from '../../core/utils/functions';
import { FormatType } from '../../core/config/format-type';

interface Props {
	search: FilterMatchingValidations;
	data: MatchingValidationsModel[];
	lastUpdate?: Date;
	productList: ProductModel[];
	onChangeSort: (search: FilterMatchingValidations, loading: boolean) => void;
	onChangeData: (data: MatchingValidationsModel, index: number) => void;
	onRemoveData: (index: number) => void;
}


export const TableMatchingValidations: React.FC<Props> = (props) => {
	const [matchingValid, setMatchingValid] = React.useState<number>(0);
	const [modalShowEdit, setModalShowEdit] = React.useState(false);
	const [selectIndex, setSelectIndex] = React.useState(-1);
	// const [lastUpdateIndex, setLastUpdateIndex] = React.useState(-1);
	const { toastShow } = useToast();
	const { user } = useAuth();

	const _matchingValidationsRepository = new MatchingValidationsRepository();

	React.useEffect(() => {
		if (props.data.length > 0) {
			setMatchingValid(props.data[0].total_validated);
		}
	}, [props.data]);


	function saveItem(data: MatchingValidationsModel, indexAux: number, increment: number): Promise<any> {
		return new Promise((resolve, reject) => {
			if (data && user) {
				props.onChangeData(data, indexAux);
				data.loading = true;
				data.user_id = user.id;
				data.user_full_name = user.name;
				data.date_update_by_user = new Date();
				_matchingValidationsRepository.add(data).then((res) => {
					data.loading = false;
					data.id = res.data.id
					props.onChangeData(data, indexAux);
					props.data[0].total_validated = matchingValid > 0 ? (matchingValid + increment) : matchingValid;
					resolve(res);
				}).catch((_err) => {
					data.loading = false;
					// props.onChangeData({ ...data }, selectIndex);
					toastShow({
						title: 'Erro!',
						subTitle: `Erro no ${data.competitor_product_description}!`,
						type: 'error',
					});
					reject();
				});
			} else {
				reject();
			}
		});
	}

	function reject(selectDelete: MatchingValidationsModel, index: number) {
		selectDelete.match_status = 3;
		saveItem(selectDelete, index * 1, -1).then(() => {
			toastShow({
				title: 'Sucesso!',
				subTitle: `${selectDelete.competitor_product_description} rejeitado com sucesso!`,
				type: 'success',
			});
		})
	}

	function reset(data: MatchingValidationsModel, index: number) {
		const indexAux = index * 1;
		data.loading = true;
		props.onChangeData(data, indexAux);
		// setLastUpdateIndex(indexAux);
		_matchingValidationsRepository.reset(data).then((res: MatchingValidationsModel) => {
			data.match_status = res.match_status;
			data.product_id = res.product_id;
			data.product_description = res.product_description;
			data.is_similar = res.is_similar;
			data.date_update_by_user = res.date_update_by_user;

			data.loading = false;
			props.data[0].total_validated = data.match_status != res.match_status ? matchingValid : (matchingValid > 0 ? (matchingValid - 1) : matchingValid);

			props.onChangeData(data, indexAux);
			// getMatchingValid();
			// props.onChangeSort(props.search, true);
			toastShow({
				title: 'Sucesso!',
				subTitle: 'Dados resetados com sucesso!',
				type: 'success',
			});
		}).catch((_err) => {
			data.loading = false;
			props.onChangeData(data, indexAux);
			toastShow({
				title: 'Erro!',
				subTitle: 'Erro ao resetar dados!',
				type: 'error',
			});
		});

	}

	function changeSort(value: string, prop: string) {
		const aux: any = {};
		aux[prop] = value;
		props.search.sort = aux;
		props.onChangeSort(props.search, true);
	}

	function saveDate(data: MatchingValidationsModel, indexIntro: number, increment: number) {

		saveItem(data, indexIntro * 1, increment).then((res) => {
			props.data[0].total_validated = matchingValid + 1
			toastShow({
				title: 'Sucesso!',
				subTitle: `${data.competitor_product_description} modificado com sucesso!`,
				type: 'success',
			});
		})
	}


	return (
		<>
			<div className='d-flex justify-content-between'>
				<div className="font-size-15">
					Matching Confirmados:&nbsp;
					{matchingValid == null ?
						<Spinner size="sm" animation="border" role="status" />
						:
						<b>{matchingValid}</b>
					}
				</div>
				<div className="font-size-15 min-width-290">
					Última atualização do modelo:&nbsp;
					{props.lastUpdate === undefined ?
						<Spinner size="sm" animation="border" role="status" />
						:
						props.lastUpdate &&
						<label className="click absolute margin-top--1">
							<b>{moment(props.lastUpdate).format(FormatType.MOMENT_FORMAT)}</b>
						</label>

					}
				</div>
			</div>
			<Table striped bordered hover responsive size="sm">
				<thead>
					<tr>
						<TableRow
							sort={props.search.sort.competitor_id}
							onSort={(ev: any) => changeSort(ev, 'competitor_id')}
						>
							Concorrente ID
						</TableRow>

						<TableRow
						// sort={props.search.sort.competitor_ncm}
						// onSort={(ev: any) => changeSort(ev, 'competitor_ncm')}
						>
							NCM&nbsp;
							<OverlayTrigger overlay={<Tooltip> Nomenclatura Comum do Mercosul</Tooltip>}>
								<label className="click absolute margin-top--1">
									<FaInfoCircle /> &nbsp;
								</label>
							</OverlayTrigger>
							{props.search.sort.competitor_ncm}
						</TableRow>

						<TableRow
							sort={props.search.sort.competitor_product_id}
							onSort={(ev: any) => changeSort(ev, 'competitor_product_id')}
						>
							Produto Concorrente
						</TableRow>
						<TableRow
							sort={props.search.sort.product_id}
							onSort={(ev: any) => changeSort(ev, 'product_id')}

						>Produto Laredo</TableRow>

						<TableRow
							sort={props.search.sort.is_similar}
							onSort={(ev: any) => changeSort(ev, 'is_similar')}

						>Tipo de <br /> Matching</TableRow>

						<TableRow
							sort={props.search.sort.match_rating}
							onSort={(ev: any) => changeSort(ev, 'match_rating')}

						>Avaliação <br />do Modelo </TableRow>

						{/* <TableRow >Validado </TableRow> */}
						<TableRow
							sort={props.search.sort.date_update_by_user}
							onSort={(ev: any) => changeSort(ev, 'date_update_by_user')}
						>Última <br /> Atualização </TableRow>
						<TableRow >Ações </TableRow>

					</tr>
				</thead>
				<tbody>
					{props.data.length === 0 && (
						<tr className="table-row-header text-center">
							<td colSpan={8}> Nenhum registro encontrado! </td>
						</tr>
					)}
					{props.data.map((_, index) => (


						<tr key={`TableMatchingValidations_${index}`}
							className=
							{_.match_status == 3 ? 'bg-lightred' : _.match_status == 2 ? 'bg-ligthgreen' : ""}
						>
							{/* Concorrente ID */}
							<td className='text-center'>
								{_.competitor_id}
								<Badge pill className="ml-1" bg="primary" title={_.competitor_issuer_type ? competitorTypeList.find(x => x.id == _.competitor_issuer_type)?.description : ""}>
									{_.competitor_issuer_type}
								</Badge>
							</td>
							{/* NCM Concorrente */}
							<td className='text-center'>
								{_.competitor_ncm}
							</td>
							{/* Produto Concorrente */}
							<td>
								[<b>{_.competitor_product_id}</b>] {_.competitor_product_description}
							</td>
							{/* Produto Laredo */}
							<td>
								<div className='d-flex justify-content-between'>
									<label className={('w-100 align-vertical' + (!_.product_id && ' text-center'))} >
										{_.product_id ?
											<>
												[<b>{_.product_id}</b>] {_.product_description}
											</>
											:
											<label >Produto não encontrado</label>
										}

									</label>
									<Button
										title='Editar'
										variant="outline-primary"
										size="sm"
										onClick={() => {
											setSelectIndex(index * 1)
											setModalShowEdit(true)
										}}
									>
										<FaEdit />
									</Button>
								</div>
							</td>
							{/* Similar / Específico */}
							<td className='text-center'>
								{_?.is_similar ? "Similar" : "Especifíco"}
							</td>
							{/* Avaliação do Modelo */}
							<td className='text-center'>
								{_.match_rating == null ? 'N/A' : get_matching_rating_description(_.match_rating)}
							</td>
							{/* Validado */}
							<td className='text-center relative'>
								{_.loading ?
									<Spinner animation="grow" size="sm" /> :
									_.date_update_by_user &&
									<OverlayTrigger
										placement="top"
										overlay={
											<Tooltip id={'tooltip_' + index} >
												{_.user_full_name}
											</Tooltip>
										}
									>
										<label>
											{moment.utc(_.date_update_by_user).local().format('DD/MM/YYYY HH:mm')}
										</label>
									</OverlayTrigger>
								}
							</td>
							<td className='text-center'>

								<Button
									title='Confirmar'
									size="sm"
									className="mr-1 "
									disabled={_.match_status != 0}
									variant={_.match_status == 0 ? "success" : "light"}
									onClick={() => {
										_.match_status = 2;
										saveDate(_, index, 1)
									}}
								>
									<FaCheck />
								</Button>

								<Button
									size="sm"
									title='Rejeitar'
									className="mr-1 "
									variant="outline-danger"
									disabled={!(_.match_status == 2 || _.match_status == 0)}
									onClick={() => {
										// setSelectDelete(_)
										reject(_, index);
									}}
								>
									<IoClose />

								</Button>

								<Button
									size="sm"
									title='Reverter'
									className="mr-1 "
									variant="outline-secondary"
									disabled={!(_.date_update_by_user)}
									onClick={() => {
										reset(_, index)
									}}
								>
									<FaUndo />
								</Button>
							</td>
						</tr >
					))}
				</tbody>
			</Table>
			<div>
				<TablePagination
					onChangePage={(value) => {
						props.search.page = value;
						props.onChangeSort(props.search, true);
					}}
					currentPage={props.search.page}
					pageSize={props.search.pageSize}
					count={props.search.total}
				></TablePagination>
			</div>

			<ModalMatchingValidations
				show={modalShowEdit}
				data={props.data.length > selectIndex ? props.data[selectIndex] : undefined}
				productList={props.productList}
				onHide={() => {
					setModalShowEdit(false);
				}}
				onSave={(data) => {
					saveDate(data, selectIndex, data.match_status != props.data[selectIndex].match_status ? 1 : 0);
					setModalShowEdit(false);
				}}
			/>

			{/* <ModalConfirm
				title={`Confirma se deseja rejeitar o produto  ?`}
				subTitle={`Você deseja rejeitar do produto laredo ${selectDelete?.product_description}?`}
				show={!!selectDelete}
				onHide={() => {
					setSelectDelete(undefined);
				}}
				confirm={reject}
			/> */}

		</>
	);
};
