import * as React from 'react';
import { Badge, Table } from 'react-bootstrap';
import TablePagination from '../../components/TablePagination';
import TableRow from '../../components/TableRow';
import { formatDecimal, formatPercent, get_matching_rating_description, get_matching_status_description } from '../../core/utils/functions';
import { DemandVariabilityModel } from '../../core/model/demand_variability';
import { FilterDemandVariability } from '../../core/repositories/demand_variability.repository';
import moment from 'moment';

interface Props {
	search: FilterDemandVariability;
	data: DemandVariabilityModel[];
	onChangeSort: (search: FilterDemandVariability, loading: boolean) => void;
}

export const TableDemandVariability: React.FC<Props> = (props) => {
	function changeSort(value: string, prop: string) {
		const aux: any = {};
		aux[prop] = value;
		props.search.sort = aux;
		props.onChangeSort(props.search, true);
	}


	return (
		<>
			{props.data.length > 0 && (
				<div className="text-end font-size-15">
					Última atualização do modelo: <b>{moment(props.data[0].date_creation).format('DD/MM/YYYY')}</b>
				</div>
			)}
			<Table striped bordered hover size="sm">
				<thead>
					<tr>

						<TableRow
							sort={props.search.sort.distribution_center}
							onSort={(ev: any) => changeSort(ev, "distribution_center")}
						>
							Centro de Distribuição
						</TableRow>
						<TableRow
							sort={props.search.sort.product_category}
							onSort={(ev: any) => changeSort(ev, "product_category")}
						>
							Categoria de Produto
						</TableRow>
						<TableRow
							sort={props.search.sort.real_value?.toString()}
							onSort={(ev: any) => changeSort(ev, "real_value")}
						>
							{props.search.value}
						</TableRow>
						<TableRow
							sort={props.search.sort.forecast_value?.toString()}
							onSort={(ev: any) => changeSort(ev, "forecast_value")}
						>
							Previsão
						</TableRow>

						<TableRow
							sort={props.search.sort.forecast_mae?.toString()}
							onSort={(ev: any) => changeSort(ev, "forecast_mae")}
						>
							Previsão MAE
						</TableRow>

						<TableRow
							sort={props.search.sort.forecast_mape?.toString()}
							onSort={(ev: any) => changeSort(ev, "forecast_mape")}
						>
							Previsão MAPE
						</TableRow>

						<TableRow
							sort={props.search.sort.consensus_value?.toString()}
							onSort={(ev: any) => changeSort(ev, "consensus_value")}
						>
							Consenso
						</TableRow>

						<TableRow
							sort={props.search.sort.consensus_mae?.toString()}
							onSort={(ev: any) => changeSort(ev, "consensus_mae")}
						>
							Consensus MAE
						</TableRow>

						<TableRow
							sort={props.search.sort.consensus_mape?.toString()}
							onSort={(ev: any) => changeSort(ev, "consensus_mape")}
						>
							Consensus MAPE
						</TableRow>

						<TableRow
							sort={props.search.sort.real_value_py?.toString()}
							onSort={(ev: any) => changeSort(ev, "real_value_py")}
						>
							{props.search.value == "Revenue" ? "Receita A-1" : "Quantidade A-1"}
						</TableRow>

						<TableRow
							sort={props.search.sort.real_py_mae?.toString()}
							onSort={(ev: any) => changeSort(ev, "real_py_mae")}
						>
							{props.search.value == "Revenue" ? "Receita A-1 MAE" : "Quantidade A-1 MAE"}
						</TableRow>

						<TableRow
							sort={props.search.sort.real_py_mape?.toString()}
							onSort={(ev: any) => changeSort(ev, "real_py_mape")}
						>
							{props.search.value == "Revenue" ? "Receita A-1 MAPE" : "Quantidade A-1 MAPE"}
						</TableRow>




					</tr>
				</thead>
				<tbody>
					{props.data.length === 0 && (
						<tr className="table-row-header text-center">
							<td colSpan={14}> Nenhum registro encontrado! </td>
						</tr>
					)}
					{props.data.map((_, index) => (
						<tr className="text-center" key={index}>
							<td>{_.distribution_center}</td> {/* distribution_center */}
							<td>{_.product_category}</td> {/* product category */}
							<td>
								{formatDecimal(_.real_value, props.search.value == "Revenue")}
							</td>
							{/* sale */}
							<td>
								{formatDecimal(
									_.forecast_value,
									props.search.value == "Revenue"
								)}
							</td>
							{/* forecast */}
							<td>
								{formatDecimal(_.forecast_mae, props.search.value == "Revenue")}
							</td>
							{/* forecast mae */}
							<td>{formatPercent(_.forecast_mape)} </td> {/* forecast mape */}
							<td>
								{formatDecimal(
									_.consensus_value,
									props.search.value == "Revenue"
								)}
							</td>
							{/* consensus */}
							<td>
								{formatDecimal(
									_.consensus_mae,
									props.search.value == "Revenue"
								)}
							</td>
							{/* consensus mae */}
							<td>{formatPercent(_.consensus_mape)} </td>
							{/*  consensus mape */}
							<td>
								{formatDecimal(
									_.real_value_py,
									props.search.value == "Revenue"
								)}
							</td>
							{/* sale y-1 */}
							<td>
								{formatDecimal(_.real_py_mae, props.search.value == "Revenue")}
							</td>
							{/* sale y-1 mae */}
							<td>{formatPercent(_.real_py_mape)}</td> {/* sale y-1 mape */}

						</tr>
					))}
				</tbody>
			</Table>
			<div>
				<TablePagination
					onChangePage={(value) => {
						props.search.page = value;
						props.onChangeSort(props.search, true);
					}}
					currentPage={props.search.page}
					pageSize={props.search.pageSize}
					count={props.search.total}
				></TablePagination>
			</div>
		</>
	);
};
