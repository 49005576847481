// @flow
import * as React from 'react';
import { useEffect, useState } from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';
import './index.scss';

interface Props {
	// items: [];
	onChangePage: (value: number) => void;
	currentPage: number;
	pageSize: number;
	count: number;
}

const TablePagination: React.FC<Props> = (props) => {
	const [pageObj, setPageObj] = useState<any>({});

	useEffect(() => {
		setPage(props.currentPage);
	}, [props.count, props.currentPage]);

	function setPage(page: number) {
		var { pageSize } = props;
		var pager = pageObj;

		if (page < 1 || page > props.count) {
			return;
		}
		pager = getPager(props.count, page, pageSize);
		setPageObj(pager);
		if (page !== props.currentPage) {
			props.onChangePage(page);
		}
	}

	function getPager(totalItems: number, currentPage: number, pageSize: number) {
		// default to first page
		currentPage = currentPage || 1;
		// default page size is 10
		pageSize = pageSize || 10;
		// calculate total pages
		var totalPages = Math.ceil(totalItems / pageSize);

		var startPage = 0,
			endPage;
		if (totalPages <= 5) {
			// less than 10 total pages so show all
			startPage = 1;
			endPage = totalPages;
		} else {
			// more than 10 total pages so calculate start and end pages
			if (currentPage <= 3) {
				startPage = 1;
				endPage = 5;
			} else if (currentPage + 2 >= totalPages) {
				startPage = totalPages - 4;
				endPage = totalPages;
			} else {
				startPage = currentPage - 1;
				endPage = currentPage + 2;
			}
		}

		// calculate start and end item indexes
		var startIndex = (currentPage - 1) * pageSize;
		var endIndex = Math.min(startIndex + pageSize - 1, totalItems - 1);

		// create an array of pages to ng-repeat in the pager control
		var pages = Object.keys([...Array(endPage + 1 - startPage)]).map((i) => startPage + Number.parseInt(i));

		// return object with all pager properties required by the view
		return {
			totalItems: totalItems,
			pageSize: pageSize,
			totalPages: totalPages,
			startPage: startPage,
			endPage: endPage,
			startIndex: startIndex,
			endIndex: endIndex,
			pages: pages,
		};
	}

	return pageObj.totalItems ? (
		<Row className="table-pagination">
			<Col md="4" className="font-size-12 align-vertical justify-content-start align-items-end ">
				{pageObj.startIndex + 1} a {pageObj.endIndex + 1} de {props.count} &nbsp;itens
			</Col>
			<Col md="8">
				<Pagination size="sm" className="mb-0 mt-3 justify-content-end">
					<Pagination.Prev className={props.currentPage === 1 ? 'disabled' : ''} onClick={() => setPage(props.currentPage - 1)}></Pagination.Prev>

					{pageObj.startPage !== 1 && <Pagination.Ellipsis className="min-width-38 text-center" onClick={() => setPage(1)} />}

					{pageObj.pages.map((page: any, index: number) => (
						<Pagination.Item key={index} className={(props.currentPage === page ? 'active' : '') + ' min-width-38 text-center'} onClick={() => setPage(page)}>
							{page}
						</Pagination.Item>
					))}

					{pageObj.endPage !== pageObj.totalPages && <Pagination.Ellipsis className="min-width-38 text-center" onClick={() => setPage(pageObj.totalPages)} />}

					<Pagination.Next className={props.currentPage === pageObj.totalPages ? 'disabled' : ''} onClick={() => setPage(props.currentPage + 1)} />
				</Pagination>
			</Col>
		</Row>
	) : (
		<div></div>
	);
};

export default TablePagination;
