import { CollecionName } from '../config/collection-name';
import { BasicFilter } from '../model/base-model';
import { ProductMatchingModel } from '../model/product_matching';
import { Repository } from './repository';

export class FilterProductMatching extends BasicFilter<ProductMatchingModel> {
	id = '';
	description = '';

	department_id = '';
	section_id = '';
	category_id = '';
	product_id = '';
	match_rating!: number;
	competitor_id = '';
	competitor_product_id = '';
	competitor_issuer_type = '';
	competitor_issuer_type_description = '';
	match_status!: number;
	match_status_description = '';

	// descriptions for download button click event
	department_description = '';
	section_description = '';
	category_description = '';
	product_description = '';
	match_rating_description = '';
	is_similar?: boolean;

}

export class ProductMatchingRepository extends Repository<ProductMatchingModel> {
	constructor() {
		super(CollecionName.PRODUCT_MATCHING);
	}

	getLastUpdate() {
		return this.api.get(`${this.collectionName}_last_update`).then((res) => {
			return res.data[0].date_creation;
		});
	}

	download(search: any) {
		search = { ...search };
		search.pageSize = search.total + 1;
		return this.api.post(this.collectionName + '_download', search);
	}
}
