import { CollecionName } from "../config/collection-name";
import { BasePriceCatalogMinimumModel } from "../model/base_price_catalog_minimum";
import { Repository } from "./repository";

export class FilterBasePriceCatalogMinimum {
  user_id: string = "";
  product_category_id: string = "";
  product_id: string = "";
  state_id: string = "";

  page = 1;
  pageSize = 60;
  total = 0;
}

export class BasePriceCatalogMinimumRepository extends Repository<BasePriceCatalogMinimumModel> {
  constructor() {
    super(CollecionName.BASE_PRICE_CATALOG_MINIMUM);
  }

  deleteAll(): Promise<Boolean> {
    return this.api.post(this.collectionName + "_delete").then((res: any) => {
      return res.data.success;
    });
  }
}
